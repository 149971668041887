import { motion } from 'framer-motion'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Constants from '../../../../Constants'
import { useUploadMultipleMutation, useUploadMutation } from '../../../../Redux/Api/Api'
import {useImportCardsMutation, useLazyGetOrganisationSettingsQuery, useLazyGetTypeDataQuery} from '../../../../Redux/Api/Organisations';
import { useAppSelector } from '../../../../Redux/store'
import { CSVLoader } from '../../../../Services/CSVLoader'
import { Hydra } from '../../../../Utils/Hydra'
import BusyArea from '../../../Shared/BusyArea'
import Button from '../../../Shared/Form/Button'
import FilePicker from '../../../Shared/Form/FilePicker'
import Form from '../../../Shared/Form/Form'
import SevereError from '../../../Shared/Form/SevereError'
import Success from '../../../Shared/Form/Success'
import { ModalContext } from '../../../Shared/Modal/Modal'
import Column from '../../../Shared/Table/Column'
import Data from '../../../Shared/Table/Data'
import Header from '../../../Shared/Table/Header'
import Row from '../../../Shared/Table/Row'
import Table from '../../../Shared/Table/Table'
import Title from '../../../Shared/Title'
import CustomSwitch from "../../../Shared/Form/CustomSwitch";
import { useTranslation } from "react-i18next";
import MultipleImageInput from '../../../Shared/Form/MultipleImageInput';
import { Table as MuiTable, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import {useAddCardPhotoMutation, useAddMultiplePhotoMutation, useGetCardQuery} from '../../../../Redux/Api/Cards';
import ConfirmationDialog from "../../../Shared/Form/ConfirmationDialog";


const CardsImportPage = () => {
    const modalContext = useContext(ModalContext)
    const {t} = useTranslation()
    const org_id = useAppSelector((s) => s.organisation.id)!
    const [getOrganisationsSettings, {data: settings}] = useLazyGetOrganisationSettingsQuery()
    const [cardFile, setCardFile] = useState<File | null>(null)
    const [fileIsValid, setFileIsValid] = useState<string>('')
    const [successMessage, setSuccessMessage] = useState<string>('')
    const [successMessagePhotoUpload, setSuccessMessagePhotoUpload] = useState<string>('')
    const [csvData, setCsvData] = useState<Array<Array<string>>>()
    const [busy, setBusy] = useState(false)
    const [isDataOpen, setIsDataOpen] = useState(false)
    const [uploadFile] = useUploadMutation()
    const [uploadMultipleFile] = useUploadMultipleMutation()
    const [importCards] = useImportCardsMutation()
    const [imagePreviews, setImagePreviews] = useState<string[]>([]);
    const [showSelectedFiles, setShowSelectedFiles] = useState(false);
    const filePickerRef = useRef<HTMLInputElement | null>(null);
    const [selectedFileNames, setSelectedFileNames] = useState<string[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<FileList>();
    const [addPhoto] = useAddCardPhotoMutation();
    const [addMultiplePhoto] = useAddMultiplePhotoMutation();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');

    const [sendMailState, setSendMailState] = useState(false);


    useEffect(() => {
        if (org_id) getOrganisationsSettings(org_id)
    }, [org_id])

    useEffect(() => {
        if (!cardFile) return

        setFileIsValid('')

        ;(async () => {
            const trimmedFile = trimCSV(await cardFile.text());
            const loadedData = await CSVLoader.load(trimmedFile, t)

            setCsvData(loadedData.data!)
            setIsDataOpen(true)

            if (loadedData.error) {
                return setFileIsValid(loadedData.error)
            }
        })()

    }, [cardFile])

    const trimCSV = (csv: string) => {
        const lines = csv.split('\n').map(line => line.trim());

        // Verwijder lege regels aan het einde
        while (lines[lines.length - 1] === '') {
            lines.pop();
        }

        return lines.join('\n');
    };

    const submit = async ({ cards: file, send }: { cards: File; send: string }) => {
        if (busy) return;
        if (null == file) return setFileIsValid(t("cards:list:addCSV") || " ");
        if (fileIsValid) return setFileIsValid(t("cards:list:problemCSV") || " ");

        if (!(await modalContext.show(t("cards:list:importMessage"), t("cards:list:importMessagePrefix")))) {
            modalContext.hide();
            return;
        }

        modalContext.hide()

        setBusy(true)
        setSuccessMessage('')
        setFileIsValid('')

        try {
            const file_id = await uploadFile({
                file
            }).unwrap()

            const { successfulRecords, duplicates } = await importCards([org_id, file_id, send == 'true']).unwrap()

            if (duplicates.length > 0) {
                setFileIsValid(t('cards:list:duplicatesFound', { duplicates: duplicates.join(', ') }) || " ");
                setBusy(false);
                return;
            }

            if (send === 'true') {
                setSuccessMessage(t('cards:list:importSendsucces', { number: successfulRecords }) || "succes");
            } else {
                setSuccessMessage(t('cards:list:importSuccess', { importedCount: successfulRecords }) ?? "");
            }

            window.scrollTo(0, 0);
        } catch (e) {
            let err = e as Hydra.Error;
            setFileIsValid(err['hydra:description']);
            setBusy(false);
        }
        setTimeout(() => setBusy(false), 200);
    }

    const handleImageUpload = (files: FileList | null) => {
        if (files) {
            const names: string[] = [];

            Array.from(files).forEach((file) => {
                const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, '');
                names.push(fileNameWithoutExtension);

                const reader = new FileReader();
                reader.onload = (e) => {

                    setImagePreviews((imagePreviews) => {
                        if (e.target && typeof e.target.result === 'string') {
                            return [...imagePreviews, e.target?.result]
                        }
                        return imagePreviews;
                    });

                };
                reader.readAsDataURL(file);

            });
            setSelectedFileNames(names);
            setSelectedFiles(files);

            setShowSelectedFiles(true);
        }
    };

    const handleRemoveImage = (index: number) => {
        const updatedFileNames = [...selectedFileNames];
        const updatedPreviews = [...imagePreviews];

        updatedFileNames.splice(index, 1);
        updatedPreviews.splice(index, 1);

        setSelectedFileNames(updatedFileNames);
        setImagePreviews(updatedPreviews);
    };

    const clearSelectedImages = () => {
        setSelectedFiles(undefined);
        setSelectedFileNames([]);
        setImagePreviews([]);
        setShowSelectedFiles(false);
    }


    const amountOfSelectedFiles = () => {
        return selectedFileNames.length;
    }

    let totalSuccessfullyUploaded = 0;

    const submitPhotos = async () => {
        if (busy || !selectedFiles) {
            return;
        }

        const totalFilesToUpload = selectedFiles.length;
        const maxBatchSize = 20;
        let currentBatchStart = 0;
        let currentBatchEnd = Math.min(maxBatchSize, totalFilesToUpload);
        setBusy(true);

        const uploadBatch = async (start: number, end: number) => {
            try {
                const formData = new FormData();
                for (let i = start; i < end; i++) {
                    formData.append(`files[${i - start}]`, selectedFiles[i]);
                    formData.append(`fileNames[${i - start}]`, selectedFileNames[i]);
                }

                const response = await uploadMultipleFile(formData).unwrap();
                const data = JSON.parse(response);

                for (const uploadId of data.uploadIds) {
                    const index: number = data.uploadIds.indexOf(uploadId);
                    const cardId = data.cardIds[index];

                    try {
                        await addPhoto({
                            card_id: cardId,
                            upload_id: uploadId
                        });
                        totalSuccessfullyUploaded++;
                    } catch (error) {
                        console.error('Image upload failed: ', error)
                        setBusy(false);
                    }
                }
                currentBatchStart = end;
                currentBatchEnd = Math.min(currentBatchStart + maxBatchSize, totalFilesToUpload);

                if (currentBatchStart < totalFilesToUpload) {
                    uploadBatch(currentBatchStart, currentBatchEnd);
                } else {
                    setBusy(false);
                    clearSelectedImages();

                    const successMessage = `Succesvol ${totalSuccessfullyUploaded} van de ${totalFilesToUpload}  foto's geupload.`;

                    setSuccessMessagePhotoUpload(successMessage);

                    totalSuccessfullyUploaded = 0;
                }
            } catch (error) {
                console.error('Image upload failed: ', error);
                setBusy(false);
            }
        };

        totalSuccessfullyUploaded = 0;

        uploadBatch(currentBatchStart, currentBatchEnd);
    };

    const runMail = () => {
        setDialogTitle(t('cards:list:warningTitle') || '');
        setDialogMessage(t('cards:list:activationEmailWarningPrompt') || '');
        setDialogOpen(true);
    }

    const handleDialogClose = async (confirm: boolean) => {
        setDialogOpen(false);

        if (confirm) {
            setSendMailState(true);
        } else {
            setSendMailState(false);
        }
    };

    const handleClick = (value: boolean) => {
        if (value) {
            runMail();
        } else {
            setSendMailState(false);
        }
    };

    return (
        <>
            <ConfirmationDialog
                open={dialogOpen}
                title={dialogTitle}
                message={dialogMessage}
                onClose={(confirm) => handleDialogClose(confirm)}
            />
            <Title text={t('cards:import:importCards')} textNotBold />
            <BusyArea busy={busy}>
                <Form className='mb-2' submit={submit}>
                    <Success text={successMessage} />
                    <SevereError subtitle={t('cards:import:error') as string} error={fileIsValid} />
                    <div className='mb-2'>
                        <p style={{whiteSpace: "pre-line"}}>
                            {t('cards:import:description')}
                        </p>
                    </div>
                    <div className='mb-2'>
                        <p style={{whiteSpace: "pre-line" }}>
                            <b>
                                {t('cards:import:warning')}
                            </b>
                        </p>
                    </div>
                    <div className='mb-2 w-96'>
                        <a className='my-2' href={ Constants.DOWNLOAD_URL_CARDS_TEMPLATE } download><Button secondary nosubmit title={t('cards:import:download')}/></a>
                    </div>
                    <FilePicker onChange={ setCardFile } accept={['text/csv']} label={t('cards:import:cardsFile')} id='cards' />
                    { csvData && (
                        <>
                            <Button
                                title={ isDataOpen ? t('cards:import:hideExample') : t('cards:import:watchExample') }
                                className='mb-6'
                                nosubmit
                                action={ () => setIsDataOpen(s => !s) } />
                            <motion.div
                                initial={{ maxHeight: '0vh' }}
                                animate={{ maxHeight: isDataOpen ? '50vh' : '0vh', maxWidth: '150vh'}}
                                className='flex flex-col overflow-hidden w-full'>
                                <Table itemsPerPage={ 50 }>
                                    <Header>
                                        <Column name={t('cards:list:line')}/>
                                        <Column name={t('cards:list:initials')}/>
                                        <Column name={t('cards:list:lastname')}/>
                                        <Column name={t('cards:list:email')}/>
                                        <Column name={t('cards:list:membershipId')}/>
                                        <Column name={t('cards:list:activeFrom')}/>
                                        <Column name={t('cards:list:activeUntil')}/>
                                        <Column name={t('cards:list:type')}/>
                                        <Column name={t('cards:list:dateOfBirth')}/>
                                        <Column name={t('cards:list:location')}/>
                                        <Column name={t('cards:list:phone')}/>
                                        <Column name={t('cards:list:department')}/>
                                        {settings?.familyCards ? (<Column name={t('cards:edit:numberOfPeople')}/>) : null}
                                        <Column name={t('cards:list:countryCode')}/>
                                        <Column name={t('cards:list:discount')}/>
                                        <Column name={t('cards:list:team')}/>
                                    </Header>
                                    <Data>
                                        { csvData.map((row, index) => (
                                            <Row
                                                errorAware
                                                key={ index }
                                                index={ index }
                                                data={ row } />
                                        )) }
                                    </Data>
                                </Table>
                            </motion.div>
                        </>
                    )}
                    <CustomSwitch id='send' label={t('cards:list:sendMail?')} onClick={handleClick} state={sendMailState} />
                    <Button title={t('cards:list:import')} />
                </Form>
            </BusyArea>
            {/*
            <Success text={ successMessagePhotoUpload }/>
            <BusyArea>
                {settings?.photo ? (
                    <>
                        <div>
                            <Title text={t('cards:photo:photoUpload')} />
                            <div className='mb-5'>
                                <p style={{whiteSpace: "pre-line"}}>
                                    {t('cards:photo:importPicturesDescription')}
                                </p>
                            </div>
                        </div>
                        {!showSelectedFiles ? (
                            <MultipleImageInput onUpload={handleImageUpload} />
                        ) : (
                            <div>
                                <div className='flex mb-3' style={{justifyContent: 'space-between'}}>
                                    <div>
                                        {!showSelectedFiles && <MultipleImageInput onUpload={handleImageUpload} />}
                                    </div>

                                    <div className='flex justify-end items-center'>
                                        <div className='mr-6'>
                                            {amountOfSelectedFiles()}{t('cards:photo:amountOfImages')}
                                        </div>
                                        <Button title={t('cards:photo:removePictures')} action={clearSelectedImages} secondary/>
                                        <Button title={t('cards:photo:importPictures')} action={submitPhotos} className='mx-2' />
                                    </div>
                                </div>

                                <motion.div
                                    initial={{ maxHeight: '0vh' }}
                                    animate={{ maxHeight: '100vh' , maxWidth: '100vw'}}
                                    className='flex flex-col overflow-scroll w-full'>
                                    <MuiTable className='border'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className='font-bold border' >
                                                    {t('cards:photo:photoPreview')}
                                                </TableCell>
                                                <TableCell className='font-bold border'>
                                                    {t('cards:photo:photoFileName')}
                                                </TableCell>
                                                <TableCell className='font-bold border'>
                                                    {t('cards:photo:removePicture')}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedFileNames.map((fileName, index) => (
                                                <TableRow key={index}>
                                                    <TableCell className='w-1/3 p-0'>
                                                        {imagePreviews[index] && (
                                                            <img
                                                                src={imagePreviews[index]}
                                                                alt={`Preview of ${fileName}`}
                                                                width={150}
                                                                height={150}
                                                                style={{border: '1px solid beige'}}
                                                            />
                                                        )}
                                                    </TableCell>
                                                    <TableCell style={{ textAlign: 'center' }} className='w-1/3 p-0'>{fileName}</TableCell>
                                                    <TableCell className='w-1/3 p-0'>
                                                        <Button title={t('cards:photo:removePicture')} action={() => handleRemoveImage(index)} secondary/>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </MuiTable>
                                </motion.div>
                            </div>

                        )}
                    </>
                ) : null}
            </BusyArea>*/}
        </>
    )
}

export default CardsImportPage
