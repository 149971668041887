import { motion } from 'framer-motion'
import React from 'react'

export interface TitleProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    text: string,
    subtitle?: string,
    textNotBold? : boolean
}

const Title = (props: TitleProps) => {

    const { text, subtitle, className, textNotBold, ...restProps } = props

    return (
        <div {...restProps} style={{ fontSize: '40px'}} className={` mb-5 -mt-[10px] md:mt-[30px] lg:mt-0 ${textNotBold ? '' : 'font-bold'}  ${textNotBold ? 'font-normal text-4xl' : 'font-title text-2xl'} ${className}`}>
            <div className='mb-1'>
                <motion.div
                    transition={{ duration: 0.5 }}
                    animate={{ translateY: ['1.5em', '0em'], opacity: [0, 1] }}>
                    { text }
                </motion.div>
            </div>
            { subtitle && (
                <div style={{ fontSize: '16px'}} className='overflow-hidden font-normal'>
                    <motion.div
                        transition={{ duration: 0.5, delay: 0.15 }}
                        animate={{ translateY: ['-1.5em', '0em'], opacity: [0, 1] }}>
                        { subtitle }
                    </motion.div>
                </div>
            ) }
        </div>
    )
}

export default Title