import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons'
import React, { useContext, useState, useEffect } from 'react'
import {useLazyGetOrganisationTypesQuery, usePostNotificationMutation} from '../../../../Redux/Api/Organisations';
import { useAppSelector } from '../../../../Redux/store'
import { Hydra } from '../../../../Utils/Hydra'
import { all, max, min } from '../../../../Utils/InValidation'
import BusyArea from '../../../Shared/BusyArea'
import Button from '../../../Shared/Form/Button'
import Error from '../../../Shared/Form/Error'
import Form from '../../../Shared/Form/Form'
import Input, { FailedRequirements } from '../../../Shared/Form/Input'
import Success from '../../../Shared/Form/Success'
import { ModalContext } from '../../../Shared/Modal/Modal'
import Title from '../../../Shared/Title'
import Dropdown from "../../../Shared/Form/Dropdown";
import LoadingPage from "../../LoadingPage";
import Switch from "../../../Shared/Form/Switch";
import {useTranslation} from "react-i18next";

const NotifyPage = () => {

    const modalContext = useContext(ModalContext)
    const { t } = useTranslation()

    const org_id = useAppSelector((s) => s.organisation.id)!
    const [getTypes, { data: types }] = useLazyGetOrganisationTypesQuery()

    const [ error, setError ] = useState('')
    const [ busy, setBusy ] = useState(false)
    const [ sendNotifications ] = usePostNotificationMutation()
    const [ message, setMessage ] = useState('')
    const [ sendType, setSendType ] = useState(true)

    useEffect(() => {
        if(org_id)
            getTypes(org_id)
    }, [ org_id ])

    const handleSendType = () => {
        // @ts-ignore
        if (types.length > 0) {
            setSendType(!sendType)
        } else {
            setError(t('modal:noTypes') as string)
        }
    }

    const submit = async (data: { title: string, content: string, url: string, cardType: string }) => {
        if(busy) return
        if(FailedRequirements(data,'title', 'content')) return setError(t('modal:error') as string)

        if(data.url !== ""){
            if(!data.url.toLowerCase().includes('http')) {
                setError(t('modal:urlError') as string)
                setBusy(false)
                return;
            }
        }

        if(!(await modalContext.show(t('modal:attention') as string, t('modal:notification') as string))) {
            modalContext.hide()
            return
        }

        modalContext.hide()

        setBusy(true)
        setError('')

        try {
            await sendNotifications({
                id: org_id,
                title: data.title,
                body: data.content,
                url: data.url,
                type: data.cardType
            })

            if (data.cardType === 'all') {
                const sentMessage = t('notification:sent');
                setMessage(sentMessage);
            } else {
                const sentMessage = t('notification:sentType');
                setMessage(sentMessage);
            }
            
        } catch(e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
            setBusy(false)
        }

        setBusy(false)
    }

    if(!types) return <LoadingPage/>

    return (
        <>
            <Title text={t('notification:title')} subtitle={t('notification:subtitle') as string} />
            <BusyArea busy={ busy }>
                <Error text={ error }  />
                <Success text={ message } />
                <Form submit={ submit }>
                    <Input
                        required
                        invalidator={ all(min(3), max(100)) }
                        label={t('notification:tableTitle')}
                        type='switch'
                        id='title'/>
                    <input type='checkbox' className='ml-1 h-4 w-4 mb-6' title='test' checked={ sendType } onChange={ handleSendType } /> <span className='ml-2 opacity-70 text-xl'>{t('notification:checkbox')}</span>
                    { sendType ? (
                        <input type='hidden' value='all' name='cardType' />
                    ): (
                        <Dropdown label={t('notification:type')} id='cardType' options={
                            Object.fromEntries(types.map(type => ([type.id, type.name])))
                        } />
                    ) }
                    <Input
                        required
                        large
                        invalidator={ all(min(3), max(250)) }
                        label={t('notification:message')}
                        id='content'/>
                    <Input
                        invalidator={ all(min(3), max(250)) }
                        label={t('notification:url')}
                        sublabel={t('notification:optional') as string}
                        id='url'/>
                    <Button title={t('notification:send')} iconright icon={ faPaperPlane } />
                </Form>
            </BusyArea>
        </>
    )
}

export default NotifyPage
