import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion } from 'framer-motion'
import moment from 'moment'
import React, {useContext, useEffect, useState, useTransition} from 'react'
import {useGetNewsQuery, usePullFeedMutation, usePutNewsFeedMutation} from '../../../../Redux/Api/Organisations';
import { putFeedLocal } from '../../../../Redux/State/Organisation'
import { useAppDispatch, useAppSelector } from '../../../../Redux/store'
import { Hydra } from '../../../../Utils/Hydra'
import BusyArea from '../../../Shared/BusyArea'
import Button from '../../../Shared/Form/Button'
import Error from '../../../Shared/Form/Error'
import Form from '../../../Shared/Form/Form'
import Input from '../../../Shared/Form/Input'
import Success from '../../../Shared/Form/Success'
import { ModalContext } from '../../../Shared/Modal/Modal'
import Column from '../../../Shared/Table/Column'
import Data from '../../../Shared/Table/Data'
import Header from '../../../Shared/Table/Header'
import Row from '../../../Shared/Table/Row'
import Table from '../../../Shared/Table/Table'
import Title from '../../../Shared/Title'
import LoadingPage from '../../LoadingPage'
import {useNavigate} from "react-router-dom";
import {useDeleteNewsMutation} from "../../../../Redux/Api/News";
import {useTranslation} from "react-i18next";

const NewsPage = () => {

    const { withModal } = useContext(ModalContext)
    const { t, i18n } = useTranslation()

    const feed = useAppSelector((s) => s.organisation.settings?.news?.feed)
    const org_id = useAppSelector((s) => s.organisation.id)!
    const { data: news, refetch } = useGetNewsQuery(org_id)
    const [ successMessage, setSuccessMessage ] = useState('')

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [ putFeed ] = usePutNewsFeedMutation()
    const [ pullFeed ] = usePullFeedMutation()
    const [ deleteNews ] = useDeleteNewsMutation()

    const [ busy, setBusy ] = useState(false)
    const [ error, setError ] = useState('')
    const [ rssInfoOpen, setRssInfoOpen ] = useState(false)

    const submit = async ({ feed } : { feed: string }) => {
        if(busy) return

        setBusy(true)
        setError('')
        setSuccessMessage('')

        try {
            await putFeed({ id: org_id, feed }).unwrap()

            await pullFeed(org_id).unwrap()

            refetch()

            dispatch(putFeedLocal(feed))
            setSuccessMessage(t('organisation:news:successImport') as string)
        } catch(e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
            setBusy(false)
        }

        setTimeout(() => setBusy(false), 200)
    }

    const deleteNewsAction = async (id: string) => {
        if(busy) return

        setBusy(true)
        setError('')
        setSuccessMessage('')

        try {
            await deleteNews(id)

            if(org_id)
                refetch()
        } catch(e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
        }

        setBusy(false)
    }

    if(!news) return <LoadingPage/>

    return (
        <>
            <Title text={t('organisation:news:title')} subtitle={t('organisation:news:subtitle') as string} textNotBold/>
            <Error text={ error }/>
            <Success text={ successMessage }/>
            <BusyArea busy={ busy }>
                <Button
                    title={t('organisation:news:add')}
                    action={() => navigate(`/${i18n.language}/dashboard/organisation/addnews`)}
                    className='absolute right-10 top-14 float-right absolute w-[16rem]'
                />
                <Form submit={ submit } className='mb-10'>
                    <div>
                        {t('organisation:news:rssMessage')}
                        <FontAwesomeIcon onClick={ () => setRssInfoOpen(s => !s)} className='cursor-pointer opacity-60 ml-1' icon={ faQuestionCircle }/>
                    </div>
                    <motion.div
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: rssInfoOpen ? 1 : 0, opacity: rssInfoOpen ? 1 : 0 }}
                        className='origin-top-left bg-light-100 p-4 mt-2'>{t('organisation:news:rssMessageDescription')} <a target='_blank' className='text-accent hover:text-accent-light' href='https://x-interactive.nl/contact/'>{t('organisation:news:ask')}</a>.</motion.div>
                    <Input
                        className='mt-2'
                        id='feed'
                        initial={ feed }
                        label='RSS Feed URL'/>
                    <div className='flex gap-10'>
                        <Button title={t('organisation:news:import')}/>
                    </div>
                </Form>
                <Table withSearch>
                    <Header>
                        <Column name={t('organisation:news:tableTitle')} />
                        <Column name={t('organisation:news:url')} />
                        <Column name={t('organisation:news:date')} />
                    </Header>
                    <Data>
                        { news.map((item, index) => (
                            <Row
                                key={ index }
                                action={ withModal({ title: 'Wil je deze link openen?', body: '' }, () => window.open(item.url, '_blank')) }
                                data={[ item.title, item.url ? item.url : '-', moment(item.date).format('DD-MM-YYYY') ]}
                                index={ index }
                                editUrl={ `/${i18n.language}/dashboard/organisation/newsedit/` + item.id }
                                deleteAction={ () => deleteNewsAction(item.id) }/>
                        ))}
                    </Data>
                </Table>
            </BusyArea>
        </>
    )
}

export default NewsPage
