import Filter from "../../../Datagrid/Filter";
import TextField from "../../../../Components/Form/TextField";
import React, {useEffect, useState} from "react";
import {RequestFilter} from "../../../../Redux/Api/Request/DynamicListRequest";
import { withStyles } from '@mui/styles';
import {useTranslation} from "react-i18next";
import {Button, MenuItem} from "@mui/material";
import DropdownInput from "../../../Shared/Form/DropdownInput";
import {useLazyGetTypeDataQuery} from "../../../../Redux/Api/Organisations";
import {useAppSelector} from "../../../../Redux/store";
import AppAccordion from "../../../AppAccordion";
import CalendarInput from '../../../Shared/Form/CalendarInput'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle} from '@fortawesome/pro-solid-svg-icons';

type OrdersFilterProps = {
    filters: RequestFilter,
    onFiltersChanged: (filters: RequestFilter) => void;
    todate: Date;
    fromdate: Date;
    check: string;
    classes: any
};

const styles = {
    filterField: {
        width: 'calc(25% - 20px)',
        '@media screen and (max-width: 767px)': {
            width: 'calc(50% - 20px)'
        },
        margin: 10
    },
};

const formatDateToYMD = (date: Date,number:number): string => {
    date.setDate(date.getDate() + number)
    return date.toISOString().slice(0, 10);
};

function CardsFilter({ filters: initialFilters, onFiltersChanged, classes, todate, fromdate, check}: OrdersFilterProps) {

    const { t, i18n } = useTranslation()
    const navigate = useNavigate()

    const org_id = useAppSelector((s) => s.organisation.id)!
    const [ getOrganisationTypes, { data: orgTypes } ] = useLazyGetTypeDataQuery();
    const [ openFilters, setOpenFilters ] = useState(false);

    const [selectedType, setSelectedType] = useState('');
    const [activeFilters, setActiveFilters] = useState<{ field: string, value: string, displayname: string, displayvalue:string}[]>([]);

    const addOrUpdateFilter = (field:string, value:string, displayname:string, displayvalue:string) => {
        const existingFilterIndex = activeFilters.findIndex(filter => filter.field === field);
        if (existingFilterIndex !== -1) {
          const updatedFilters = [...activeFilters];
          updatedFilters[existingFilterIndex] = { field, value, displayname, displayvalue };
          setActiveFilters(updatedFilters);
          onFiltersChanged(updatedFilters);
        } else {
          setActiveFilters([...activeFilters, { field, value, displayname, displayvalue }]);
          onFiltersChanged([...activeFilters, { field, value, displayname, displayvalue }]);
        }
    };

    const removeFilter = (field: string) => {
        const updatedFilters = activeFilters.filter(filter => filter.field !== field);
        setActiveFilters(updatedFilters);
        onFiltersChanged(updatedFilters);
    };

    const renderActiveFilters = () => {
        return (
            <div className="flex d-flex flex-wrap flex-row pl-2">
                {activeFilters.map((filter, index) => (
                    filter.value.trim() !== "" && (
                        <div key={index} className="btn btn-outline-secondary m-1 bg-blue-500 rounded-3xl text-center px-4 py-2 text-white hover:bg-blue-400">
                            {filter.displayname}: {filter.displayvalue}
                            <FontAwesomeIcon className="ml-2" icon={faXmarkCircle} onClick={() => removeFilter(filter.field)} />
                        </div>
                    )
                ))}
            </div>
        );
    };

    useEffect(() => {
        const filters = [];

        if (fromdate) {
            const fromDate = new Date(fromdate);
            filters.push({ field: 'fromdate', value: formatDateToYMD(fromDate, 0),displayname: t('cards:edit:from'), displayvalue: formatDateToYMD(fromDate, 0)});
        }
        if (todate) {
            const toDate = new Date(todate);
            filters.push({ field: 'todate', value: formatDateToYMD(toDate, 0),displayname: t('cards:edit:until'), displayvalue: formatDateToYMD(toDate, 0)});
        }
        if (check) {
            filters.push({ field: 'check', value: check,displayname: t('cards:list:scan'),displayvalue: check === 'checkin' ? t('cards:list:checkIn') :  check === 'checkout' ? t('cards:list:checkOut') : check === 'check' ?  t('cards:list:check'): check === 'checkedin' ?  t('cards:list:CurrentlyCheckedin'): ''});
        }

        filters.push({ field: 'status', value: 'active',displayname: t('cards:list:status') ,displayvalue: t('cards:list:active') });
        setActiveFilters(filters);
        onFiltersChanged(filters);
    }, [fromdate, todate, check]);



    useEffect(() => {
        if(org_id) {
            getOrganisationTypes({
                id: org_id
            })
        }
    }, [ org_id ])

    const resetFilters = () => {
        clearFilters();
    };

    const clearFilters = () => {
        setActiveFilters([]);
        onFiltersChanged([]);
    };

    return (
    <Filter>
        <TextField
            label={t('cards:list:membershipId')}
            variant="outlined"
            name="filter-membershipId"
            className={classes.filterField}
            onChange={(e) => addOrUpdateFilter('membershipId', e.target.value, t('cards:list:membershipId'), e.target.value)}
            size="small"
            value={activeFilters.find(filter => filter.field === 'membershipId')?.value || ''}
        />

        <TextField
            label={t('cards:list:initials')}
            variant="outlined"
            name="filter-initials"
            className={classes.filterField}
            onChange={(e) => addOrUpdateFilter('initials', e.target.value, t('cards:list:initials'), e.target.value )}
            size="small"
            value={activeFilters.find(filter => filter.field === 'initials')?.value || ''}
        />

        <TextField
            label={t('cards:list:lastname')}
            variant="outlined"
            name="filter-lastname"
            className={classes.filterField}
            onChange={(e) => addOrUpdateFilter('lastname', e.target.value, t('cards:list:lastname'), e.target.value)}
            size="small"
            value={activeFilters.find(filter => filter.field === 'lastname')?.value || ''}
        />

        <TextField
            label={t('cards:list:email')}
            variant="outlined"
            name="filter-email"
            className={classes.filterField}
            onChange={(e) => addOrUpdateFilter('email', e.target.value, t('cards:list:email'), e.target.value)}
            size="small"
            value={activeFilters.find(filter => filter.field === 'email')?.value || ''}
        />
        <br />
        {openFilters ? null :
            <>
                <Button
                    variant="text"
                    className="mt-3 mx-2"
                    onClick={() => {
                        setOpenFilters(!openFilters);
                    }}
                >
                    {t('cards:list:moreFilters')}
                </Button>
                <Button
                    variant="text"
                    className="mt-3 mx-2"
                    onClick={() => {
                        resetFilters();
                    }}
                >
                    {t('cards:list:resetFilters')}
                </Button>
                <br />
                <div>
                    {renderActiveFilters()}
                </div>
            </>
        }
        {openFilters ?
            <>
                <TextField
                    label={t('cards:list:scan')}
                    variant="outlined"
                    name="filter-checkedIn"
                    className={classes.filterField}
                    onChange={(e) =>  addOrUpdateFilter('check', e.target.value,t('cards:list:scan'),  e.target.value === 'checkin' ? t('cards:list:filerCheckedIn') : e.target.value === 'checkout' ?  t('cards:list:checkedOut') :  e.target.value === 'check'? t('cards:list:checked') :  e.target.value === 'checkedin'? t('cards:list:CurrentlyCheckedin'): '')}
                    size="small"
                    select
                    value={activeFilters.find(filter => filter.field === 'check')?.value || ''}
                    style={{
                        border: activeFilters.some(filter =>
                            (filter.field === 'fromdate' && filter.value !== '') ||
                            (filter.field === 'todate' && filter.value !== '')
                          ) ? (
                            !activeFilters.some(filter =>
                              filter.field === 'check' && filter.value !== '' && filter.value !== 'checkedin'
                            ) ? '2px solid #FF0058' : '2px solid #0175FF'
                          ) :
                            !activeFilters.some(filter =>
                              filter.field === 'check' && filter.value !== '' && filter.value !== 'checkedin'
                            ) ? 'none' : '2px solid #0175FF'
                      }}
                    >
                    <MenuItem value=''>{t('cards:list:filerCheckedIn')}</MenuItem>
                    <MenuItem value='checkin'>{t('cards:list:checkedIn')}</MenuItem>
                    <MenuItem value='checkout'>{t('cards:list:checkedOut')}</MenuItem>
                    <MenuItem value='checkedin'>{t('cards:list:CurrentlyCheckedin')}</MenuItem>
                    <MenuItem value='check'>{t('cards:list:checked')}</MenuItem>

                </TextField>

                <TextField
                    label={t('cards:list:status')}
                    variant="outlined"
                    name="filter-status"
                    className={classes.filterField}
                    onChange={(e) => addOrUpdateFilter('status', e.target.value, t('cards:list:status'), e.target.value === 'active' ? t('cards:list:active') :  e.target.value === 'inactive' ? t('cards:list:inactive') : e.target.value === 'blocked' ?  t('cards:list:blocked'): e.target.value === 'pushToken' ?  t('cards:list:pushtoken'):  e.target.value === 'nopushToken' ?  t('cards:list:NoPushToken'):'')}
                    size="small"
                    select
                    value={activeFilters.find(filter => filter.field === 'status')?.value || ''}
                >
                    <MenuItem value=''>{t('cards:list:filterStatus')}</MenuItem>
                    <MenuItem value='active'>{t('cards:list:active')}</MenuItem>
                    <MenuItem value='pushToken'>{t('cards:list:pushtoken')}</MenuItem>
                    <MenuItem value='nopushToken'>{t('cards:list:NoPushToken')}</MenuItem>
                    <MenuItem value='inactive'>{t('cards:list:inactive')}</MenuItem>
                    <MenuItem value='blocked'>{t('cards:list:blocked')}</MenuItem>
                </TextField>

                <TextField
                    label={t('cards:list:mail?')}
                    variant="outlined"
                    name="filter-mail"
                    className={classes.filterField}
                    onChange={(e) => addOrUpdateFilter('mail', e.target.value, t('cards:list:mail'), e.target.value === '1' ? t('cards:list:yes') : e.target.value === '0' ?  t('cards:list:no'): ''  )}
                    size="small"
                    select
                    value={activeFilters.find(filter => filter.field === 'mail')?.value || ''}
                >
                    <MenuItem value=''>{t('cards:list:filerCheckedIn')}</MenuItem>
                    <MenuItem value='1'>{t('cards:list:yes')}</MenuItem>
                    <MenuItem value='0'>{t('cards:list:no')}</MenuItem>
                </TextField>

                <TextField
                    label={t('cards:list:type')}
                    variant='outlined'
                    name='filter-type'
                    className={classes.filterField}
                    onChange={(e) => {
                        const value = e.target.value
                        setSelectedType(value)
                        const selectedOption = orgTypes?.find(option => option.name === value)
                        if (selectedOption) {
                            addOrUpdateFilter('type', selectedOption.id, t('cards:list:type'), value)
                        } else {
                            addOrUpdateFilter('type', value, t('cards:list:type'), value)
                        }
                    }}
                    value={activeFilters.find(filter => filter.field === 'type')?.displayvalue || ''}
                    size='small'
                    select
                >
                    <MenuItem value=''>{t('cards:list:filterTypes')}</MenuItem>
                    {orgTypes?.map((t) => (
                        <MenuItem key={t.name} value={t.name}>
                            {t.name}
                        </MenuItem>
                    ))}
                </TextField>

                    <div className="flex flex-row ml-3 mr-6 justify-center">
                        <div className="flex-1 mr-2">
                            <CalendarInput
                                label=""
                                valuelabel={t('cards:edit:from') || ''}
                                valueChange={(date: Date) => addOrUpdateFilter('fromdate', formatDateToYMD(date, 1), t('cards:edit:from'), formatDateToYMD(date, 0))}
                                initial={activeFilters.find(filter => filter.field === 'fromdate')?.value || ''}
                                className="mt-2 rounded-md"
                                style={{
                                    border: activeFilters.some(filter =>
                                        (filter.field === 'check' && filter.value !== '' && filter.value !== 'checkedin') ||
                                        (filter.field === 'todate' && filter.value !== '')
                                      ) ? (
                                        !activeFilters.some(filter =>
                                          filter.field === 'fromdate' && filter.value !== ''
                                        ) ? '2px solid #FF0058' : '2px solid #0175FF'
                                      ) :
                                        !activeFilters.some(filter =>
                                          filter.field === 'fromdate' && filter.value !== ''
                                        ) ? 'none' : '2px solid #0175FF'
                                }}
                            />
                        </div>
                        <div className="flex-1 ml-2">
                            <CalendarInput
                                label=""
                                valuelabel={t('cards:edit:until') || ''}
                                valueChange={(date: Date) => addOrUpdateFilter('todate', formatDateToYMD(date, 1), t('cards:edit:until'), formatDateToYMD(date, 0))}
                                initial={activeFilters.find(filter => filter.field === 'todate')?.value || ''}
                                className="mt-2 rounded-md"
                                style={{
                                    border: activeFilters.some(filter =>
                                      (filter.field === 'check' && filter.value !== '' && filter.value !== 'checkedin') ||
                                      (filter.field === 'fromdate' && filter.value !== '')
                                    ) ? (
                                      !activeFilters.some(filter =>
                                        filter.field === 'todate' && filter.value !== ''
                                      ) ? '2px solid #FF0058' : '2px solid #0175FF'
                                    ) :
                                      !activeFilters.some(filter =>
                                        filter.field === 'todate' && filter.value !== ''
                                      ) ? 'none' : '2px solid #0175FF'
                                }}


                            />
                        </div>
                    </div>
                <br />
                <Button
                    variant="text"
                    className="mx-2"
                    onClick={() => {
                        setOpenFilters(!openFilters);
                   }}
                >
                    {t('cards:list:hideFilters')}
                </Button>
                <Button
                    variant="text"
                    className="mx-2"
                    onClick={() => {
                        navigate(`/${i18n.language}/dashboard/cards/list`);
                        window.location.reload();
                    }}
                >
                    {t('cards:list:resetFilters')}
                </Button>
                <br />
                <div>
                    {renderActiveFilters()}
                </div>
            </>
        : null}
    </Filter>

    );
}

export default withStyles(styles)(CardsFilter)
