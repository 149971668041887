import React, { useContext, useState, useEffect } from 'react'
import {
    useLazyGetOrganisationSettingsQuery,
    useLazyGetOrganisationTypesQuery,
    usePostNotificationMutation, usePutCardInfoMutation
} from '../../../../Redux/Api/Organisations';
import { useAppSelector } from '../../../../Redux/store'
import BusyArea from '../../../Shared/BusyArea'
import Error from '../../../Shared/Form/Error'
import Success from '../../../Shared/Form/Success'
import Title from '../../../Shared/Title'
import { DeviceFrameset } from 'react-device-frameset'
import 'react-device-frameset/styles/marvel-devices.min.css'
import IconSVG from '../../../../Assets/icon.svg?component'
import QRCode from "qrcode.react";
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import LoadingPage from "../../LoadingPage";
import Button from "../../../Shared/Form/Button";
import {ModalContext} from "../../../Shared/Modal/Modal";
import {Hydra} from "../../../../Utils/Hydra";
import {useTranslation} from "react-i18next";
import {Block, Person} from "@mui/icons-material";
import { text } from 'stream/consumers';
import { Box } from 'victory';

const AppBuilderpage = () => {
    const org_id = useAppSelector((s) => s.organisation.id)!
    const theme = useAppSelector((s) => s.organisation.settings?.theme)
    const [getSettings, { data: settings }] = useLazyGetOrganisationSettingsQuery()
    const [ putCardInfo ] = usePutCardInfoMutation()
    const [ error, setError ] = useState('')
    const [ successMessage, setSuccessMessage ] = useState('')
    const [ busy, setBusy ] = useState(false)
    const [ fileBlob, setFileBlob ] = useState('')
    const modalContext = useContext(ModalContext)
    const { t } = useTranslation()

    const [name, setName] = useState('');
    const [displayMode, setDisplayMode] = useState('')
    const [cardInfo1, setCardInfo1] = useState('');
    const [cardInfo2, setCardInfo2] = useState('');
    const [cardInfo3, setCardInfo3] = useState('');
    const [cardInfo4, setCardInfo4] = useState('');

    useEffect(() => {
        if(org_id) {
            getSettings(org_id)
        }
    }, [ org_id ])

    useEffect(() => {
        if(settings) {
            setName(settings.name)
            settings.photo ? setDisplayMode(settings.displayMode) : setDisplayMode('qrOnly')
            setCardInfo1(settings.cardInfo[0])
            setCardInfo2(settings.cardInfo[1])
            setCardInfo3(settings.cardInfo[2])
            setCardInfo4(settings.cardInfo[3])
        }
    }, [ settings ])

    useEffect(() => {
        if(!theme) return

        setFileBlob(theme.icon)
    }, [ theme ])

    const saveSettings = async () => {
        if(busy)return;

        setBusy(true);
        setError('');
        setSuccessMessage('');

        try{
            const outData = [
                cardInfo1,
                cardInfo2,
                cardInfo3,
                cardInfo4
            ]

            await putCardInfo({
                id: org_id,
                cardInfo: outData,
                name: name,
                displayMode: displayMode
            }).unwrap()
        } catch (e) {
            let err = e as Hydra.Error;
            setError(err['hydra:description']);
        }
        setBusy(false);
    }

    const handleNameChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setName(e.target.value)
    }

    const handleDisplayModeChange = (e: { target: { value: React.SetStateAction<string>}}) => {
        setDisplayMode(e.target.value)
    }

    const handleValueChange = (e: any) => {
        if (e.target.id == 0) {
            setCardInfo1(e.target.value)
        } else if (e.target.id == 1) {
            setCardInfo2(e.target.value)
        } else if (e.target.id == 2) {
            setCardInfo3(e.target.value)
        } else if (e.target.id == 3) {
            setCardInfo4(e.target.value)
        }
    }

    if(!settings) return <LoadingPage/>

    return (
        <>
            <Title text='Digipas.app' subtitle={t('cards:app:title') as string} textNotBold/>
            <Error text={ error }  />
            <Success text={ successMessage } />

            <BusyArea busy={ busy }>
            <Button
                    title={t('cards:app:save')}
                    action={ modalContext.withModal({ title: t('modal:title'), body: t('modal:changes') },  saveSettings) }
                    className='ml-0 sm:ml-80 top-[10rem] sm:top-24 lg:top-14 absolute w-[14rem]'
                />
                <div className='scale-[0.8] sm:scale-100 -ml-12 -mb-14 -mt-12 sm:-mt-0 sm:-ml-0 sm:-mb-0'>
                <DeviceFrameset device="iPhone X" color="black">
                    <div style={{background: 'white', padding: 10, paddingBottom: 20, paddingTop: 20}}>
                        <IconSVG className='mt-6 ml-6 w-6 h-6 text-brand'/>
                    </div>
                    { fileBlob && <img style={{background: 0, position: "absolute", left: 147, top: 40 }} className='w-[80px] h-[80px]' src={ fileBlob } alt='Logo' /> }
                    <div style={{background: '#F6F8FA', width: '100%', height: '100%', padding: 20}}>
                        <div style={{width: '100%', height: 45}}>Sponsor:</div>
                        <div style={{background: 'white', margin: -20, paddingTop: 15, paddingLeft: 140, paddingBottom: 40}}>
                            <IconSVG className='text-brand' style={{float: 'left'}}/>
                            <div style={{float: 'left', paddingLeft: 10}}>Digipas</div>
                        </div>
                        <div style={{width: '100%', paddingTop: 40}}>
                            <div style={{fontSize: 20, fontWeight: 'bold'}}>Hi
                                <FormControl sx={{ position: 'absolute', marginTop: -1, marginLeft: 1, minWidth: 120, height: 10 }} size="small">
                                    <Select
                                        native
                                        defaultValue={settings.name}
                                        onChange={handleNameChange}
                                    >
                                        <option className='font-sans' value="name">{t('cards:app:fullname')}</option>
                                        <option className='font-sans' value="firstname">{t('cards:list:initials')}</option>
                                        <option className='font-sans' value="lastname">{t('cards:list:lastname')}</option>
                                        <option className='font-sans' value="membershipId">{t('cards:list:membershipId')}</option>
                                    </Select>
                                </FormControl>
                            </div>
                            <div style={{fontSize: 12}}>{t('cards:app:welcomeMessage')}</div>
                        </div>
                        <div style={{marginLeft: '-10px', width: '335px', marginRight: '-10px', padding: 10, borderRadius: 10, background: "white", textAlign: "center", paddingBottom: 5}}>
                            <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                {
                                    displayMode === 'qrOnly' ? (
                                        <QRCode
                                            value='https://www.youtube.com/watch?v=dQw4w9WgXcQ'
                                            id='qrcode'
                                            size={200}
                                            level={"H"}
                                            includeMargin={true}
                                        />
                                    ) : (displayMode === 'qrAndPhoto' ? (
                                        <>
                                            <QRCode
                                                style={{marginLeft: -16}}
                                                value='https://www.youtube.com/watch?v=dQw4w9WgXcQ'
                                                id='qrcode'
                                                size={200}
                                                level={"H"}
                                                includeMargin={true}
                                            />
                                            <Person style={{width: 165, height: 165, borderRadius: 8, backgroundColor: 'silver', color: 'dimgray'}} />
                                        </>
                                    ) : (
                                        <Person style={{width: 165, height: 165, borderRadius: 8, backgroundColor: 'silver', color: 'dimgray', margin: 18}} />
                                    ))
                                }
                            </div>
                            <FormControl sx={{m: 1}} size="small">
                                <Select
                                    disabled={!settings.photo}
                                    native
                                    defaultValue={settings.displayMode}
                                    onChange={handleDisplayModeChange}
                                >
                                    <option className='font-sans' value='qrOnly'>{t('cards:app:qrOnly')}</option>
                                    <option className='font-sans' value='qrAndPhoto'>{t('cards:app:qrAndPhoto')}</option>
                                    <option className='font-sans' value='photoOnly'>{t('cards:app:photoOnly')}</option>
                                </Select>
                            </FormControl>
                            <div className="flex justify-center mt-[-10px] mb-10">
                                <div className="absolute -right-7 bg-[#F6F8FA] rounded-full w-[75px] h-[75px] mt-[-12px]"></div>
                                <div className="flex opacity-30 mt-3">
                                    <p>- - - - - -&nbsp;</p>
                                    <IconSVG />
                                    <p className="ml-2">D I G I P A S - - - - - -</p>
                                </div>
                                <div className="absolute -left-7 bg-[#F6F8FA] rounded-full w-[75px] h-[75px] mt-[-12px]"></div>
                            </div>
                            <div style={{justifyContent: 'center', marginTop: 10, marginBottom: 5}}>
                                {settings.cardInfo.map( (value, index) =>
                                    <FormControl key={index} sx={{m: 1, maxWidth: 140}} size="small">
                                        <Select
                                            native
                                            defaultValue={value}
                                            onChange={handleValueChange}
                                            id={index.toString()}
                                        >
                                            <optgroup className='font-sans not-italic' label={t('cards:list:memberInformation') ||''}>
                                                {settings?.familyCards ? (<option value="numberOfPeople">{t('cards:edit:numberOfPeople')}</option>) : null}
                                                <option className='font-sans' value="membershipId">{t('cards:list:studentnumber')}</option>
                                                <option className='font-sans' value="types">{t('cards:list:type')}</option>
                                                <option className='font-sans' value="membershipId">{t('cards:list:membershipId')}</option>
                                                <option className='font-sans' value="team">{t('cards:list:team')}</option>
                                               
                                            </optgroup>  
                                            <optgroup className='font-sans not-italic' label={t('cards:list:registration') ||''}>
                                                <option className='font-sans' value="activeFrom">{t('cards:list:activeFrom')}</option>
                                                <option className='font-sans' value="activeUntil">{t('cards:list:activeUntil')}</option>
                                              
                                            </optgroup>
                                            <optgroup className='font-sans not-italic' label={t('cards:list:personalDetails') ||''}>
                                                <option className='font-sans' value="email">{t('cards:list:email')}</option>
                                                <option className='font-sans' value="dateOfBirth">{t('cards:list:dateOfBirth')}</option>
                                                <option className='font-sans' value="telephoneNumber">{t('cards:list:phone')}</option>
                                                <option className='font-sans' value="name">{t('cards:app:fullname')}</option>
                                               </optgroup>

                                            <optgroup className='font-sans not-italic' label={t('cards:list:other') ||''}>
                                                <option className='font-sans' value="department">{t('cards:list:department')}</option>
                                                <option className='font-sans' value="">{t('cards:app:empty')}</option>
                                                <option className='font-sans' value="location">{t('cards:list:location')}</option>
                                                <option className='font-sans' value="discount">{t('cards:list:discount')}</option>
                                                </optgroup>                                       
                                        </Select>
                                    </FormControl>
                                )}
                            </div>
                        </div>
                        <div style={{background: 'white', marginTop: 20, marginLeft: -40, marginRight: -20, padding: 20, justifyContent: 'center', display: 'flex'}}>
                            <div style={{width: '33%', marginLeft: 35, color: 'red'}}>
                                <IconSVG style={{marginLeft: 20, marginBottom: 10}}/>
                                {t('cards:app:mycard')}
                            </div>
                            <div style={{width: '33%', marginLeft: 35}}>
                                <IconSVG style={{marginLeft: 15, marginBottom: 10}}/>
                                {t('cards:app:news')}
                            </div>
                            <div style={{width: '33%', marginLeft: 35}}>
                                <IconSVG style={{marginLeft: 13, marginBottom: 10}}/>
                                {t('cards:app:socials')}
                            </div>
                        </div>
                    </div>
                </DeviceFrameset>
                </div>
            </BusyArea>
        </>
    )
}



export default AppBuilderpage