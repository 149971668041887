import { faBars, faXmark } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HTMLMotionProps, motion } from 'framer-motion'
import {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../Redux/store';
import { setSideBarOpen } from '../../../Redux/State/App';
import IconSVG from '../../../Assets/icon.svg?component';
import LanguageDropdown from '../LanguageDropdown';
import { SelectChangeEvent } from '@mui/material/Select';

export interface SidebarProps extends HTMLMotionProps<'div'> {}

const Sidebar = (props: SidebarProps) => {

    const { children, ...restProps } = props

    const isOpen = useAppSelector((s) => s.app.sidebaropen)
    const dispatch = useAppDispatch()
    const [language, setLanguage] = useState('NL');
    const [isLargeScreen, setIsLargeScreen] = useState(false)

    const {t, i18n} = useTranslation();

    const changeLanguage = (event: SelectChangeEvent) => {
        i18n.changeLanguage(event.target.value.toLowerCase());
        setLanguage(event.target.value);
    }

    const handleResize = () => {
        setIsLargeScreen(window.innerWidth >= 1024)
    };

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <>
            <motion.div
                {...restProps}
                animate={{
                    translateX: isOpen ? '0%' : '-100%',
                }}
                transition={{
                    duration: 0.3,
                }}
                className="flex fixed left-0 top-0 overflow-y flex-col w-screen md:w-80 lg:!translate-x-0 h-screen bg-light-100 z-20"
>

                <div className='flex justify-between'>
                    <div className='flex row'>
                        <IconSVG className='mt-6 ml-5 w-10 h-10 text-brand' />
                        <h1 className='mt-7 ml-3 font-bold' style={{ fontSize: '22px' }}>Digipas</h1>
                    </div>
                    <div className='flex row'>
                        <LanguageDropdown style={{width: 75, height: 1, marginTop: "1rem", marginRight: "1rem"}} />
                        <div onClick={() => dispatch(setSideBarOpen(false))} className='lg:hidden flex cursor-pointer mt-1 items-center p-4 justify-center'>
                            <div className='flex items-center justify-center hover:bg-accent hover:text-white cursor-pointer p-2'>
                                <FontAwesomeIcon className='w-6 h-6 p-1' icon={faXmark} />
                            </div>
                        </div>
                    </div>
                </div>
                {children}
            </motion.div>
            <div onClick={() => dispatch(setSideBarOpen(true))} className='lg:hidden absolute left-0 top-0 z-10 flex items-center p-4 justify-center'>
                <div className='flex items-center justify-center bg-light-200 hover:bg-accent hover:text-white cursor-pointer p-2'>
                    <FontAwesomeIcon className='w-6 h-6 p-1' icon={faBars} />
                </div>
            </div>
            <motion.div
                animate={{
                    width: isOpen ? '18rem' : '0rem',
                    paddingRight: isLargeScreen ? '20rem' : isOpen ? '20rem' : '0rem',
                }}
                className="flex lg:!w-72 h-screen"
            />
        </>
    );
};

export default Sidebar;
