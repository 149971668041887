import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

interface ConfirmationDialogProps {
    open: boolean;
    title: string;
    message: string;
    onClose: (confirmed: boolean) => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ open, title, message, onClose }) => {
    const formatMessage = (msg: string) => {
        const dateRegex = /\b[A-Za-z]{3} [A-Za-z]{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} [A-Za-z+0-9:() ]+\b/;

        const cleanedMessage = msg.replace(/\(.*?\)/g, '').trim();

        return cleanedMessage.replace(dateRegex, (match) => {
            const dateObj = new Date(match);
            const formattedDate = dateObj.toLocaleDateString('en-CA');
            return `<strong>${formattedDate}</strong>`;
        });
    };

    const { t } = useTranslation();

    return (
        <AnimatePresence>
            {open && (
                <div className="fixed flex items-center justify-center left-0 top-0 w-screen h-screen z-50">
                    <motion.div
                        initial={{ scaleX: 0.5, scaleY: 0.1, opacity: 0 }}
                        animate={{ scaleX: 1, scaleY: 1, opacity: 1 }}
                        exit={{ scaleX: 0.5, scaleY: 0.1, opacity: 0 }}
                        className="flex flex-col z-50 max-w-[90vw] md:max-w-[50vw] bg-light-200 p-8"
                    >
                        <div className="flex flex-col">
                            <h2 className="text-3xl font-bold text-red-500">{title}!</h2>
                            <div className="mt-4">
                                <p dangerouslySetInnerHTML={{ __html: formatMessage(message) }} />
                            </div>
                            <div className="flex justify-start gap-4 mt-8">
                                <button
                                    className="bg-red-500 text-white py-2 px-4 hover:bg-red-600 transition duration-300 ease-in-out uppercase text-lg"
                                    onClick={() => onClose(true)}
                                >
                                    {t('cards:list:yes')}
                                </button>
                                <button
                                    className="bg-gray-300 text-black py-2 px-4 hover:bg-gray-400 transition duration-300 ease-in-out uppercase text-lg"
                                    onClick={() => onClose(false)}
                                >
                                    {t('cards:list:no')}
                                </button>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div
                        key="background-div"
                        onClick={() => onClose(false)}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 0.3 }}
                        exit={{ opacity: 0 }}
                        className="fixed left-0 top-0 w-screen h-screen bg-black"
                    />
                </div>
            )}
        </AnimatePresence>
    );
};

ConfirmationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ConfirmationDialog;
