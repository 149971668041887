import FilePicker, {FilePickerHandle} from "./FilePicker";
import Card from "../Card/Card";
import Cropper, {ReactCropperElement} from "react-cropper";
import "cropperjs/dist/cropper.css";
import Button from "./Button";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import constants from "../../../Constants";
import { motion } from "framer-motion";
import Title from "../Title";
import * as React from "react";
import {Person} from "@mui/icons-material";

export interface ImageInputProps {
    onCrop: (blob: Blob | null) => void,
    image?: string,
    onDelete?: () => void
}

const ImageInput = (props: ImageInputProps) => {
    const {image, onCrop, onDelete} = props

    const {t} = useTranslation()
    const [fileBlob, setFileBlob] = useState('')
    const [fileName, setFileName] = useState('')
    const [upload, setUpload] = useState('')
    const [cropModal, setCropModal] = useState(false)

    const filePickerRef = useRef<FilePickerHandle|null>(null)
    const cropperRef = useRef<ReactCropperElement>(null)

    const removeImage = () => {
        if (fileBlob) {
            filePickerRef.current?.clearInput()
            setFileBlob('')
            if (onDelete) onDelete()
        }
    }

    const cancelCrop = () => {
        setCropModal(false)
        filePickerRef.current?.clearInput()
        setUpload('')
    }

    const handleCrop = () => {
        const cropper = cropperRef.current?.cropper
        if (typeof cropper === 'undefined') {
            return
        }

        cropper.getCroppedCanvas().toBlob((blob) => {
            //@ts-ignore
            blob.name = fileName
            onCrop(blob)
        })

        setFileBlob(cropper.getCroppedCanvas().toDataURL())
        setCropModal(false)
    }

    const getFileName = (file: File) => {
        if (file) {
            setFileName(file.name)
        }
    }

    useEffect(() => {
        if (upload) {
            setCropModal(true)
        }
    }, [upload])

    useEffect(() => {
        image ? setFileBlob(`${constants.API_URL}${image}`) : setFileBlob('')
    }, [image])

    return (
        <>
            <Card free className='flex flex-col pb-1' title={t('cards:list:photo')}>
                <div className='flex-grow flex items-center'>
                    {fileBlob ? (
                        <img style={{width: 120, height: 120, marginTop: 12, borderRadius: 8}} src={fileBlob} onContextMenu={(e) => e.preventDefault()}/>
                    ) : (
                        <Person style={{width: 120, height: 120, marginTop: 12, borderRadius: 8, backgroundColor: 'silver', color: 'dimgray'}} />
                    )}
                    <div className='ml-4 mt-3 flex flex-col justify-end h-[120px]'>
                        <Button title={t('cards:list:delete')} disabled={!fileBlob} nosubmit action={() => removeImage()}/>
                    </div>
                </div>
                <div className='mt-auto'>
                    <FilePicker ref={filePickerRef} label='' id='image' accept={['image/*']} onChangeBlob={setUpload} onChange={getFileName}/>
                </div>
            </Card>
            {cropModal ?
                <div className='fixed flex items-center justify-center left-0 top-0 w-screen h-screen z-40'>
                    <motion.div
                        initial={{ scaleX: 0.5, scaleY: 0.1, opacity: 0 }}
                        animate={{ scaleX: 1, scaleY: 1, opacity: 1 }}
                        exit={{ scaleX: 0.5, scaleY: 0.1, opacity: 0 }}
                        className='flex flex-col z-50 bg-light-200'
                    >
                        <div className='w-[250px] md:w-auto m-10'>
                            <Title text={t('cards:edit:crop')}/>
                            <Cropper
                                className='m-10 h-[200px] w-[200px] md:h-[400px] md:w-[400px]'
                                src={upload}
                                aspectRatio={1}
                                zoomable={false}
                                ref={cropperRef}
                            />
                            <div className='flex gap-4 mt-2 float-right'>
                                <Button action={handleCrop} nosubmit title={t('cards:edit:save')}/>
                                <Button action={cancelCrop} nosubmit title={t('cards:edit:cancel')}/>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div
                        key="background-div"
                        onClick={ () => cancelCrop() }
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 0.3 }}
                        exit={{ opacity: 0 }}
                        className='fixed left-0 top-0 w-screen h-screen bg-black'
                    />
                </div>
            : null}
        </>
    )
}

export default ImageInput