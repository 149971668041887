import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import React, {useState,} from 'react'
import { useNavigate } from 'react-router-dom'
import {useTranslation} from "react-i18next";
import {useGetReleaseNotesQuery,useDeleteReleaseNoteMutation} from '../../../../Redux/Api/ReleaseNote';
import Button from '../../../Shared/Form/Button'
import Column from '../../../Shared/Table/Column'
import Data from '../../../Shared/Table/Data'
import Header from '../../../Shared/Table/Header'
import Row from '../../../Shared/Table/Row'
import Table from '../../../Shared/Table/Table'
import Title from '../../../Shared/Title'
import LoadingPage from '../../LoadingPage'
import { Hydra } from '../../../../Utils/Hydra'
import BusyArea from '../../../Shared/BusyArea'

const ReleaseNoteList = () => {

    const navigate = useNavigate()
    const { t, i18n } = useTranslation()

    const [busy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const [ deleteReleaseNote ] = useDeleteReleaseNoteMutation()
    const { data: releaseNote, refetch } = useGetReleaseNotesQuery();

    const deleteReleaseNoteAction = async (id: string) => {
        if(busy) return

        setBusy(true)
        setError('')
        setSuccessMessage('')

        try {
            await deleteReleaseNote(id)

        } catch(e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
        }

        setBusy(false)
    }

    if(!releaseNote) return <LoadingPage/>

    return (
        <>
            <BusyArea busy={ busy }>
                <Title text={t('releasenote:Releasenotes')} textNotBold/>
                <Table withSearch withEdit withDelete>
                    <Header>
                        <Column name={t('releasenote:id')}/>
                        <Column name={t('releasenote:title')}/>
                    </Header>
                    <Data>
                        { releaseNote.map((note, i) => (
                            <Row
                                key={ i }
                                index={ i }
                                editUrl={ `/${i18n.language}/dashboard/admin/releaseNote/edit/` + note.id }
                                data={[ note.id, note.title ]}
                                deleteAction={ () => deleteReleaseNoteAction(note.id) }/>
                        )) }
                    </Data>
                </Table>
                <Button action={() => navigate(`/${i18n.language}/dashboard/admin/releaseNote/create`)} className='my-6' title={t('releasenote:createReleasenote')} icon={ faPlus } iconright />
            </BusyArea>
        </>
    )
}

export default ReleaseNoteList