import { faPlus, faTrashAlt } from '@fortawesome/pro-solid-svg-icons'
import { AnimatePresence, motion } from 'framer-motion'
import React, {useContext, useEffect, useState} from 'react'
import {usePutSocialsMutation} from '../../../../Redux/Api/Organisations';
import { all, max, min } from '../../../../Utils/InValidation'
import { putSocialsLocal } from '../../../../Redux/State/Organisation'
import { useAppDispatch, useAppSelector } from '../../../../Redux/store'
import BusyArea from '../../../Shared/BusyArea'
import Card from '../../../Shared/Card/Card'
import Button from '../../../Shared/Form/Button'
import Dropdown from '../../../Shared/Form/Dropdown'
import Form from '../../../Shared/Form/Form'
import Input from '../../../Shared/Form/Input'
import Title from '../../../Shared/Title'
import LoadingPage from '../../LoadingPage'
import { Hydra } from '../../../../Utils/Hydra'
import Error from '../../../Shared/Form/Error'
import Success from '../../../Shared/Form/Success'
import {ModalContext} from "../../../Shared/Modal/Modal";
import {useTranslation} from "react-i18next";

interface Social {
    text: string,
    url: string,
    type: 'facebook' | 'twitter' | 'instagram' | 'other' | string
}


const SocialsPage = () => {

    const org_socials = useAppSelector((s) => s.organisation.settings?.socials)
    const org_id = useAppSelector((s) => s.organisation.id)!
    const { t } = useTranslation()

    const dispatch = useAppDispatch()
    const modalContext = useContext(ModalContext)

    const [ putSocials ] = usePutSocialsMutation()

    const [ busy, setBusy ] = useState(false)
    const [ socials, setSocials ] = useState<Array<Social>>([])
    const [error, setError] = useState('');
    const [ successMessage, setSuccessMessage ] = useState('')

    useEffect(() => {
        if(!org_socials) return
        setSocials(org_socials)
    }, [ org_socials ])

    const submit = async (data: Record<string, string>) => {
        let formattedData: Array<Social> = []

        for(let [k,v] of Object.entries(data)) {
            let [index, name] = k.split('_')

            if(!formattedData[parseInt(index)]) {
                (formattedData[parseInt(index)] as any) = {}
            }

            (formattedData[parseInt(index)] as any)[name] = v
        }

        for (const social of formattedData) {
            if (!social.url.toLowerCase().includes('http')) {
                const errorMessage = t("modal:urlError") || '*error*';
                setError(errorMessage);
                return;
            }
        }



        if(busy) return

        setBusy(true)
        setError('')
        setSuccessMessage('')

        try {

            await putSocials({
                id: org_id,
                socials: formattedData
            }).unwrap()

            dispatch(putSocialsLocal(formattedData))
            setSuccessMessage(t ("organisation:socials:edited") || " ")
        } catch(e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
            setBusy(false)
        }

        setTimeout(() => setBusy(false), 200)
    }

    const addSocial = () => {
        setSocials((s) => [...s, {
            text: '',
            url: '',
            type: ''
        }])

        // 🤮
        setTimeout(() => document.getElementById(`social${ socials.length }`)?.scrollIntoView(), 100)
    }

    const deleteSocial = async (index: number) => {
        if(!(await modalContext.show(t ("organisation:socials:deleteMessage"), t ("organisation:socials:deleteMessageText")))) {
            modalContext.hide()
            return
        }

        modalContext.hide()

        setSocials((s) => {
            let cp = [...s]

            cp.splice(index, 1)

            return cp
        })
    }

    if(!org_socials) return <LoadingPage/>

    return (
        <>
            <Title text= {t('organisation:socials:title')} subtitle={t('organisation:socials:subtitle') as string} textNotBold/>
            <Error text={ error } />
            <Success text={ successMessage } />
            <Button action={ addSocial } icon={ faPlus } iconright title={t('organisation:socials:add')} />
            <BusyArea busy={ busy }>
                <div className='flex flex-col mt-2'>
                    {t('organisation:socials:description')}
                </div>
                <Form className='flex flex-col mt-6 gap-4' submit={ submit }>
                    <AnimatePresence>
                        { socials.map((s, i) => (
                            <motion.div
                                key={ JSON.stringify(s) }
                                initial={{ opacity: 0, scale: 0.9 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0, scale: 1.1, height: 0 }}
                                id={ `social${i}` }
                                className='flex flex-col flex-grow'>
                                <Card outline free>
                                    <Input id={ i + '_text' } initial={ s.text } required invalidator={ all(min(1), max(30))} label={t('organisation:socials:name')} sublabel={ 'Social ' + (i + 1) } />
                                    <Input id={ i + '_url' } initial={ s.url } required invalidator={ min(3) } label={t('organisation:socials:url')} />
                                    <Dropdown className='z-10' id={ i + '_type' } initial={ s.type } options={{ facebook: 'Facebook', twitter: 'X', instagram: 'Instagram',other: t('organisation:socials:other') as string }} label={t('organisation:socials:type')} />
                                    <div className='flex'>
                                        <Button secondary nosubmit action={() => deleteSocial(i) } title={t('organisation:socials:delete')} icon={ faTrashAlt } />
                                    </div>
                                </Card>
                            </motion.div>
                        )) }
                    </AnimatePresence>
                    <div className='flex'>
                        <Button title={t('organisation:socials:save')} />
                    </div>
                </Form>
            </BusyArea>
        </>
    )
}

export default SocialsPage
