import { AnimatePresence, motion } from 'framer-motion'
import { createContext, ReactNode, useState } from 'react'
import Button from '../Form/Button'
import Title from '../Title'
import {useTranslation} from "react-i18next";

export const ModalContext = createContext<{
    show: (title: string, body: string) => Promise<boolean>,
    hide: () => void,
    withModal: (display: { title: string, body: string }, func: (...args: any) => any) => () => Promise<void>
}>({
    show: async () => false,
    hide: () => void 0,
    withModal: () => async () => void 0
})

const Modal = ({ children }: { children: ReactNode }) => {

    const [ state, setState ] = useState({
        title: '',
        body: ''
    })

    const [ isVisible, setIsVisible ] = useState(false)
    const [ closeAction, setCloseAction ] = useState<(value: boolean) => void>(() => void 0)
    const { t } = useTranslation()

    const show = (title: string, body: string) => {

        return new Promise<boolean>((res) => {
            setIsVisible(true)
            setState({
                title,
                body
            })

            setCloseAction(() => (value: boolean) => res(value))
        })
    }

    const hide = () => {
        setIsVisible(false)
    }

    const withModal = (display: { title: string, body: string }, callback: Function) => {
        return async () => {
            let act = await show(display.title, display.body)
            if(act) callback()
            hide()
        }
    }

    return (
        <ModalContext.Provider value={{ show, hide, withModal }}>
            <AnimatePresence>
                { isVisible && (
                    <div className='fixed flex items-center justify-center left-0 top-0 w-screen h-screen z-50'>
                        <motion.div
                            initial={{ scaleX: 0.5, scaleY: 0.1, opacity: 0 }}
                            animate={{ scaleX: 1, scaleY: 1, opacity: 1 }}
                            exit={{ scaleX: 0.5, scaleY: 0.1, opacity: 0 }}
                            className='flex flex-col z-50 min-w-[33vw] bg-light-200'>
                            <div className='flex flex-col m-20'>
                                <Title text={ state.title } />
                                <div className='mt-4'>
                                    { state.body }
                                </div>
                                <div className='flex gap-4 mt-8'>
                                    <Button nosubmit title={t('cards:list:yes')} action={ () => closeAction(true) }/>
                                    <Button secondary nosubmit title={t('cards:list:no')} action={ () => closeAction(false) }/>
                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                            key="background-div"
                            onClick={ () => closeAction(false) }
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 0.3 }}
                            exit={{ opacity: 0 }}
                            className='fixed left-0 top-0 w-screen h-screen bg-black'/>
                    </div>
                ) }
            </AnimatePresence>
            { children }
        </ModalContext.Provider>
    )
}

export default Modal
