import { motion } from 'framer-motion'
import moment from 'moment'
import React, {useEffect, useRef, useState} from 'react'
import { Strings } from '../../../Strings/nl'
import Calendar from 'react-calendar'
import {useTranslation} from "react-i18next";

export interface CalendarInputProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    label: string,
    sublabel?: string,
    id?: string,
    initial?: Date | string,
    required?: boolean,
    valueChange?: (value: Date) => void
    valuelabel?: string
}


const firstToUpperCase = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

const CalendarInput = (props: CalendarInputProps) => {
    const ref = useRef(null)
    const { label, required, initial, valueChange, sublabel, id,valuelabel, className, ...restProps } = props
    const { t, i18n } = useTranslation()

    const labelOrId = id || label
    const [ value, setValue ] = useState(initial ? new Date(initial) : null)
    const [ isOpen, setIsOpen ] = useState(false)
    const [ labelvalue, setlabelvalue ] = useState(valuelabel ? valuelabel : null)

    const onChange = (date: Date) => {
        setValue(date)
        if(valueChange) valueChange(date)
        setIsOpen(false)
    }
    useEffect(() => {
        setlabelvalue(valuelabel ? valuelabel : null)
    },[valuelabel])

    useEffect(() => {
        setValue(initial ? new Date(initial) : null);
    }, [initial]);
    
    useEffect(() => {
        const checkIfClickedOutside = (e: { target: any })=> {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            // @ts-ignore
            if (isOpen && ref.current && !ref.current.contains(e.target)) {
                setIsOpen(false)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    })

    const handleInputChange = (e: { target: { value: string | number | Date } }) => {
        const newDate = new Date(e.target.value);
        if (!isNaN(newDate.getTime())) {
            setValue(newDate);
            if(valueChange) valueChange(newDate)

        }
    };

    return (
        <div {...restProps} ref={ref} className={`flex flex-col ${ className }`}>
            <label htmlFor={ labelOrId }>{ label }{ required && <span className='text-red-400'>*</span> } { sublabel && <span className='opacity-70 text-sm'>({ sublabel })</span> }</label>
            <div className='flex flex-grow relative'>
                <div onClick={ () => setIsOpen(!isOpen) } className={`flex items-center cursor-pointer border-2 border-black border-opacity-10 flex-grow p-2 px-3 ${ value ? '' :  'text-gray-300'} `}>
                    { value ? firstToUpperCase(moment(value).format('dddd DD MMMM YYYY')) : labelvalue ? labelvalue :'\u00a0' }
                </div>

                <motion.div
                    layout
                    className={`absolute mt-10 overflow-hidden border-2 ${ isOpen ? 'max-h-96 opacity-100'  : 'max-h-0 opacity-0 transition-opacity duration-1000' } flex bg-light-200 rounded-md z-40`}>
                    <input type="date" value={ value ? value.toISOString().split('T')[0] : undefined} onChange={handleInputChange} style={{ position: "absolute" , width: 500, height: 30, padding: 5}} />
                    { i18n.languages[0] != 'en' ?
                    <Calendar defaultValue={ value || undefined } onChange={ onChange } value={ value } locale="nl-NL" className={'mt-7'}/> : <Calendar defaultValue={ value || undefined } onChange={ onChange } value={ value } locale="en-US"/>}
                </motion.div>
            </div>
            <input value={ value?.toString() } type='hidden' name={ labelOrId } id={ labelOrId } />
            <input name={ '@VALID@' + labelOrId } type="hidden" value={ (!required || value != null) + '' } />
            { required && <motion.div animate={ (!required || value != null) ? { height: '0rem' } : { height: '1.2rem' } } className='text-sm overflow-hidden text-red-400 mb-2'>{ (required && value == null && t('required')) || '\u00a0' }</motion.div> }
        </div>
    )
}
export default CalendarInput
