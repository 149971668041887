import { faCardsBlank, faEdit, faEnvelope, faPlus, faQrcode, faTrashAlt } from '@fortawesome/pro-solid-svg-icons'
import moment from 'moment'
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDeleteCardMutation, usePostCardsMassActionMutation } from '../../../../Redux/Api/Cards';
import {
    useExportCardsMutation, useGetOrganisationCardsQuery,
    useLazyGetOrganisationCardsQuery, useLazyGetOrganisationSettingsQuery, useSendReminderMailMutation, useGetOrganisationQuery
} from '../../../../Redux/Api/Organisations';
import { useAppDispatch, useAppSelector } from '../../../../Redux/store'
import { Hydra } from '../../../../Utils/Hydra'
import BusyArea from '../../../Shared/BusyArea'
import Error from '../../../Shared/Form/Error'
import Success from '../../../Shared/Form/Success'
import Title from '../../../Shared/Title'
import LoadingPage from '../../LoadingPage'
import QRCode from 'qrcode.react'
import { jsPDF } from 'jspdf'
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpFromBracket, faDownToBracket, faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import {
    Button,
    Checkbox,
    LinearProgress, Menu, MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, useMediaQuery
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import AddIcon from '@mui/icons-material/Add';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import { setDataGridSelection } from "../../../../Redux/Slices/Datagrid";
import DynamicDataGrid, { DynamicDataGridHandle } from "../../../Datagrid/DynamicDataGrid";
import { GridColDef } from "@mui/x-data-grid";
import RowActions from "../../../Datagrid/RowActions";
import { RequestFilter } from "../../../../Redux/Api/Request/DynamicListRequest";
import DownloadIcon from '@mui/icons-material/Download';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import StatusRenderer from "../../../Datagrid/Renderers/StatusRenderer";
import { Theme } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import CardsFilter from './CardsFilter';
import { useTranslation } from "react-i18next";
import { ContentCopy, PersonAddAlt1, PersonRemoveAlt1, IndeterminateCheckBox } from "@mui/icons-material";
import Input from "../../../Shared/Form/Input";
import CheckedInRenderer from "../../../Datagrid/Renderers/CheckedInRenderer";
import SidebarItem from "../../../Shared/Sidebar/SidebarItem";
import { ModalContext } from "../../../Shared/Modal/Modal";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { faMobile } from '@fortawesome/pro-light-svg-icons'
import ConfirmationDialog from "../../../Shared/Form/ConfirmationDialog";

const CardsListPage = () => {

    const handleDatesChange = (data: { dataid: string[] }) => {
        if (data.dataid) {
            setSelectedData(data.dataid);
        }
    };

    const location = useLocation();
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const modalContext = useContext(ModalContext)

    const todate = location.state?.todate;
    const fromdate = location.state?.fromdate;
    const check = location.state?.check;

    const org_id = useAppSelector((s) => s.organisation.id)!
    const org_settings = useAppSelector((s) => s.organisation.settings)!
    const [getOrganisationsSettings, { data: settings }] = useLazyGetOrganisationSettingsQuery()

    const [error, setError] = useState('')
    const [busy, setBusy] = useState(false)
    const [successMessage, setSuccessMessage] = useState<string>('')

    const [getCardData] = useLazyGetOrganisationCardsQuery()
    const [exportCards] = useExportCardsMutation()

    const [deleteCard] = useDeleteCardMutation()
    const [postMassAction] = usePostCardsMassActionMutation()
    const [sendReminderMail] = useSendReminderMailMutation()

    const [qrValue, setQrValue] = useState('')
    const [lidnr, setLidnr] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [countryCode, setcountryCode] = useState('')
    const [type, setType] = useState([])
    const [dateOfBirth, setdateOfBirth] = useState('')
    const [qrModal, setQrModal] = useState(false)

    const [selectedData, setSelectedData] = useState<string[]>();
    const selectedCards = useAppSelector(state => state.datagrid.selected) as Array<string>;
    const [filters, setFilters] = useState<RequestFilter>([]);
    const [organisation, setOrganisation] = useState('');

    const gridRef = useRef<DynamicDataGridHandle | null>(null);

    const isMediumScreen = useMediaQuery('(min-width: 768px)')
    const isLargeScreen = useMediaQuery('(min-width: 1024px)')
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [rotateChevron, setRotateChevron] = useState(false)

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setRotateChevron(true)
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setRotateChevron(false)
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    const { data, isError } = useGetOrganisationQuery({ id: org_id! }, {
        refetchOnMountOrArgChange: true
    })

    useEffect(() => {
        dispatch(setDataGridSelection([])); // Clear the datagrid selection on component mount
    }, [dispatch]);

    useEffect(() => {
        if (org_id) {
            setOrganisation(org_id)
            getOrganisationsSettings(org_id)
        }
    }, [org_id])

    const onAction = async (cards: Array<string>, action: string) => {
        if (busy) return;

        setBusy(true);
        setError('');
        setSuccessMessage('');

        try {
            const responseArray = await Promise.all(cards.map(async (cardId) => {
                try {
                    const { count } = await postMassAction({
                        cards: [cardId],
                        action
                    }).unwrap();
                    return { cardId, count };
                } catch (error: any) {
                    if (action === 'checkIn' || action === 'checkOut') {
                        return { cardId, error: t('cards:list:invalidCardError') };
                    } else if (action === 'inactive') {
                        return { cardId, error: t('cards:list:inactiveError') };
                    } else {
                        return { cardId, error: error.message };
                    }
                }
            }));

            let successCount = 0;
            let errorCount = 0;

            responseArray.forEach(({ count, error }) => {
                if (count !== undefined) {
                    successCount += count;
                } else {
                    errorCount++;
                    setError(error);
                }
            });

            if (successCount > 0) {
                switch (action) {
                    case 'email':
                        setSuccessMessage(`${successCount} ${t('cards:list:succesEmail')}`);
                        break;
                    case 'delete':
                        setSuccessMessage(`${successCount} ${t('cards:list:succesDelete')}`);
                        break;
                    case 'checkIn':
                        setSuccessMessage(`${successCount} ${t('cards:list:succesCheckIn')}`);
                        break;
                    case 'checkOut':
                        setSuccessMessage(`${successCount} ${t('cards:list:succesCheckOut')}`);
                        break;
                    case 'inactive':
                        setSuccessMessage(`${successCount} ${t('cards:list:succesInactive')}`);
                        break;
                    default:
                        setSuccessMessage('');
                        break;
                }
            }

        } catch (overallError: any) {
            setError(overallError.message);
        }
        gridRef.current?.reload();

        setBusy(false);
    }

    const deleteCardAction = async (id: string) => {
        if (busy) return

        setBusy(true)
        setError('')
        setSuccessMessage('')

        try {
            await deleteCard(id)

            if (org_id)
                getCardData({
                    id: org_id
                })
        } catch (e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
        }

        setBusy(false)
        window.location.reload();
    }

    const exportCardsAction = async () => {
        if (busy) return
        setError('');
        setBusy(true);
        const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

        try {
            const page = 1;
            const pageSize = 9999999999999;
            const orderKey = 'createdAt';
            const orderValue = 'desc';

            const exportFile = await exportCards({ org_id, filters, page, pageSize, orderKey, orderValue }).unwrap();
            await sleep(20);

            window.open(exportFile, "_blank");
        } catch (e) {
            let err = e as Hydra.Error;
            setError(err['hydra:description']);
        }
        setBusy(false)
    }

    const sendReminder = async () => {
        if (busy) return;

        setBusy(true);
        setError('');
        setSuccessMessage('');

        try {
            const result = await sendReminderMail(org_id).unwrap();
            const { successCount } = result;
            if (result && 'successCount' in result) {
                if (successCount < 0) {
                    const lastReminderDate = data?.settings?.LastReminderEmail ? new Date(data.settings.LastReminderEmail) : null;
                    if (lastReminderDate) {
                        const today = new Date();
                        const diffInDays = Math.floor((today.getTime() - lastReminderDate.getTime()) / (1000 * 60 * 60 * 24));
                        if (diffInDays < 14) {
                            const timeoutDate = new Date(lastReminderDate.getTime() + (14 * 24 * 60 * 60 * 1000));
                            const formattedTimeoutDate = timeoutDate.toISOString().split('T')[0];
                            setError(` ${t('cards:list:errorReminderMail')} ${formattedTimeoutDate}`);
                        } else {
                            setError('Error');
                        }
                    }
                } else if (successCount === 0) {
                    setError(t('cards:list:errorAllPassenActive') as string);
                } else {
                    setSuccessMessage(`${successCount} ${t('cards:list:reminderMailSucces')}`);
                }
            } else {
                setError('Error');
            }
            setBusy(false);
        } catch (e) {

            let err = e as Hydra.Error;
            setError(err['hydra:description']);
            setBusy(false);

        }
    }

    const qrcode = (
        <QRCode
            value={qrValue}
            id='qrcode'
            size={1000}
            level={"H"}
            includeMargin={true}
        />
    );

    const generateQr = (id: string, lidnr: string, initials: string, lastname: string, type: Array<string>, dateOfBirth: string) => {
        setQrValue(id)
        setLidnr(lidnr)
        setFirstname(initials)
        setLastname(lastname)
        setcountryCode(countryCode)
        // @ts-ignore
        setType(type)
        setdateOfBirth(dateOfBirth)
        setQrModal(true)
    }

    const downloadQr = () => {
        let pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: [210, 297]
        })

        pdf.setFontSize(16);
        pdf.text(t('cards:list:membershipId') + ': ' + lidnr, 50, 50);
        pdf.text(t('cards:list:initials') + ': ' + firstname, 50, 60);
        pdf.text(t('cards:list:lastname') + ': ' + lastname, 50, 70);
        pdf.text(t('cards:list:type') + ': ' + type, 50, 80);
        pdf.text(t('cards:list:dateOfBirth') + ': ' + dateOfBirth, 50, 90);
        // @ts-ignore
        let base64Image = document.getElementById('qrcode').toDataURL()
        pdf.addImage(base64Image, 'png', 55, 100, 100, 100)

        pdf.save('QR.pdf')

        setQrModal(false)
    }

    function isCanvasElement(element: HTMLElement): element is HTMLCanvasElement {
        return element instanceof HTMLCanvasElement;
    }

    const downloadQrAsJpg = () => {
        let qrCanvas = document.getElementById('qrcode');

        if (qrCanvas && isCanvasElement(qrCanvas)) {
            let base64Image = qrCanvas.toDataURL('image/jpeg');
            let downloadLink = document.createElement('a');

            downloadLink.href = base64Image;
            downloadLink.download = 'QR.jpg';
            downloadLink.click();
        } else {
            console.error('error')
        }
        setQrModal(false)
    }

    const columns: GridColDef[] = [
        {
            field: 'membershipId',
            headerName: t('cards:list:number') as string,
            flex: 1,
        },
        {
            field: 'initials',
            headerName: t('cards:list:initials') as string,
            flex: 1,
        },
        {
            field: 'lastname',
            headerName: t('cards:list:lastname') as string,
            flex: 1,
        },
        {
            field: 'createdAt',
            headerName: t('cards:list:createdAt') as string,
            flex: 1,
            renderCell: params => { return moment(params.row.createdAt).format('DD-MM-YYYY') },
        },
        {
            field: 'block',
            headerName: t('cards:list:block') as string,
            flex: 1,
            valueGetter: (params) => {
                const card = params.row
                if (card.block) {
                    return t('datagrid:block');
                } else if (moment(card.activeFrom).format('YYYYMMDD') > moment().format('YYYYMMDD')) {
                    return t('datagrid:inactive');
                } else if (moment(card.activeUntil).format('YYYYMMDD') < moment().format('YYYYMMDD')) {
                    return t('datagrid:inactive');
                } else {
                    return t('datagrid:active');
                }
            },
            renderCell: params => { return <StatusRenderer card={params.row} /> }
        },
        {
            field: 'isCheckedIn',
            headerName: t('cards:list:checkedIn') as string,
            flex: 1,
            renderCell: params => { return <CheckedInRenderer card={params.row} familyCards={settings?.familyCards} /> }
        },
        {
            field: 'countryCode',
            headerName: t('cards:list:countryCode') as string,
            flex: 1,
        },
        {
            field: 'emailactivation',
            headerName: '✉',
            width: 75,
            disableColumnMenu: true,
            hideSortIcons: true,
            renderCell: params => { return params.row.emailactivation ? <CheckIcon /> : <CloseIcon />; }
        },
        {
            field: 'activated',
            headerName: '',
            width: 75,
            disableColumnMenu: true,
            hideSortIcons: true,
            renderHeader: () => <FontAwesomeIcon icon={faMobile} />,
            renderCell: params => { return params.row.activated ? <CheckIcon /> : <CloseIcon />; }
        },
        {
            field: 'actions',
            headerName: '',
            flex: 1,
            disableColumnMenu: true,
            hideSortIcons: true,
            renderCell: (params) => {
                const actions = [
                    {
                        label: 'Download QR',
                        onClick: () => generateQr(params.row.id, params.row.membershipId, params.row.initials, params.row.lastname, params.row.types, params.row.dateOfBirth),
                    },
                    {
                        label: t('cards:list:copyQRText'),
                        onClick: () => {
                            navigator.clipboard.writeText(params.row.id);
                        },
                    },
                    {
                        label: t('cards:list:adjust'),
                        onClick: () => navigate(`/${i18n.language}/dashboard/cards/edit/` + params.row.id),
                    },
                    {
                        label: t('cards:list:delete'),
                        onClick: () => deleteCardAction(params.row.id),
                    }
                ];

                return <RowActions actions={actions} small={!isMediumScreen} />;
            },
        },
    ];

    const columnVisibilityModel = {
        membershipId: true,
        initials: true,
        lastname: true,
        createdAt: isLargeScreen,
        block: isMediumScreen,
        isCheckedIn: isMediumScreen,
        countryCode: isLargeScreen,
        emailactivation: isLargeScreen,
        activated: isLargeScreen,
        actions: true
    }

    const canDelete = async () => {
        onAction(selectedCards, 'delete')
    }

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');

    const openModal = () => {
        setDialogTitle(t('cards:list:warningTitle') || '');
        setDialogMessage(t('cards:list:activationEmailWarningPrompt') || '');
        setDialogOpen(true);
    }

    const handleDialogClose = async (confirm: boolean) => {
        setDialogOpen(false);

        if (confirm) {
            onAction(selectedCards, 'email')
        }
    };

    if (!org_id) return <LoadingPage />

    const actions = [
        { icon: <EmailIcon />, label: t('cards:list:sendMail'), onClick: (openModal) },
        { icon: <DeleteIcon />, label: t('cards:list:delete'), onClick: () => modalContext.withModal({ title: t('modal:title'), body: t('modal:delete') }, canDelete)() },
        { icon: <IndeterminateCheckBox />, label: t('cards:list:makeInactive'), onClick: () => onAction(selectedCards, 'inactive') }
    ];

    if (settings?.scan) {
        actions.push(
            { icon: <PersonAddAlt1 />, label: t('cards:list:checkIn'), onClick: () => onAction(selectedCards, 'checkIn') },
            { icon: <PersonRemoveAlt1 />, label: t('cards:list:checkOut'), onClick: () => onAction(selectedCards, 'checkOut') }
        );
    }

    return (
        <>
            <ConfirmationDialog
                open={dialogOpen}
                title={dialogTitle}
                message={dialogMessage}
                onClose={(confirm) => handleDialogClose(confirm)}
            />
            <Title text={t('cards:list:cards')} textNotBold />
            <Success text={successMessage} />
            <Error text={error} />
            <BusyArea busy={busy}>
                <div className='mb-4 flex gap-2 justify-end'>
                    {!isMediumScreen ? (
                        <>
                            <Button

                                onClick={handleMenuClick}
                                style={{ borderRadius: 0, border: '1px solid #D7E3F2' }}
                                className='cursor-pointer bg-white hover:bg-gray-300 text-black p-2 px-3'
                            >
                                <motion.div animate={rotateChevron ? { rotate: '0deg' } : { rotate: '180deg' }}><FontAwesomeIcon icon={faChevronDown} /></motion.div>

                                <p className='pl-2'>{t ('cards:list:moreActions')}...</p>

                            </Button>

                            <Menu
                                className='z-10'
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <MenuItem
                                    onClick={
                                        modalContext.withModal({
                                            title: `${t('modal:title')} ${data?.settings?.LastReminderEmail ? `${t('modal:lastMail')} ${data.settings.LastReminderEmail}` : ''}`,
                                            body: t('modal:reminder')
                                        }, sendReminder)
                                    }
                                    style={{ borderBottom: '1px solid #dbdbdb' }}
                                >
                                    <MailOutlineIcon className='pr-2' style={{ fontSize: '24px' }} /> {t('cards:list:sendReminder')}
                                </MenuItem>
                                <MenuItem onClick={() => { navigate(`/${i18n.language}/dashboard/cards/import`) }} style={{ borderBottom: '1px solid #dbdbdb' }}>
                                    <FontAwesomeIcon icon={faDownToBracket} className='pr-2' style={{ fontSize: '14px' }} /> {t('cards:list:import')}
                                </MenuItem>
                                <MenuItem onClick={() => { exportCardsAction() }} style={{ borderBottom: '1px solid #dbdbdb' }}>
                                    <FontAwesomeIcon icon={faUpFromBracket} className='pr-2' style={{ fontSize: '14px' }} /> {t('cards:list:export')}
                                </MenuItem>
                            </Menu>
                        </>
                    ) : (
                        <>
                            <Button style={{ borderRadius: 0, border: '1px solid #D7E3F2' }} className='bg-white hover:bg-blue-500 text-black hover:text-white p-2 px-3' onClick={modalContext.withModal({ title: `${t('modal:title')} ${data?.settings?.LastReminderEmail ? `${t('modal:lastMail')} ${data.settings.LastReminderEmail}` : ''}`, body: t('modal:reminder') }, sendReminder)}><MailOutlineIcon className='pr-2' style={{ fontSize: '24px' }} /> {t('cards:list:sendReminder')}</Button>
                            <Button style={{ borderRadius: 0, border: '1px solid #D7E3F2' }} className='bg-white hover:bg-blue-500 text-black hover:text-white p-2 px-3' onClick={() => navigate(`/${i18n.language}/dashboard/cards/import`)} ><FontAwesomeIcon icon={faDownToBracket} className='pr-2' style={{ fontSize: '14px' }} /> {t('cards:list:import')}</Button>
                            <Button style={{ borderRadius: 0, border: '1px solid #D7E3F2' }} className='bg-white hover:bg-blue-500 text-black hover:text-white p-2 px-3' onClick={exportCardsAction}><FontAwesomeIcon icon={faUpFromBracket} className='pr-2' style={{ fontSize: '14px' }} /> {t('cards:list:export')}</Button>
                        </>
                    )}
                    <Button style={{ borderRadius: 0, border: '1px solid #D7E3F2', backgroundColor: '#0175FF' }} className=' text-white p-2 px-3' onClick={() => navigate(`/${i18n.language}/dashboard/cards/create`)}><AddIcon className='lh-1 mr-1' style={{ fontSize: '20px' }} /> {t('cards:list:add')}</Button>
                </div>
                <CardsFilter filters={filters} onFiltersChanged={setFilters} todate={todate} fromdate={fromdate} check={check} />
                <DynamicDataGrid
                    actions={actions}
                    // updateInterval={30000}
                    columns={columns}
                    filter={filters}
                    organisation={org_id}
                    style={{ backgroundColor: 'white', fontSize: '16px', marginBottom: '100px' }}
                    rowHeight={60}
                    queryHook={useGetOrganisationCardsQuery}
                    sortModel={[{ field: 'createdAt', sort: 'desc' }]}
                    autoHeight
                    checkboxSelection
                    ref={gridRef}
                    datachange={handleDatesChange}
                    columnVisibilityModel={columnVisibilityModel}
                />
                <div className='opacity-0 fixed'>
                    {qrcode}
                </div>
                {qrModal ?
                    <div className='fixed flex items-center justify-center left-0 top-0 w-screen h-screen z-40'>
                        <motion.div
                            initial={{ scaleX: 0.5, scaleY: 0.1, opacity: 0 }}
                            animate={{ scaleX: 1, scaleY: 1, opacity: 1 }}
                            exit={{ scaleX: 0.5, scaleY: 0.1, opacity: 0 }}
                            className='flex flex-col z-50 min-w-[33vw] bg-light-200'>
                            <div className='flex flex-col m-20'>
                                <Title text="QR Code downloaden" />
                                <div className='mt-4'>
                                    <p>
                                        {t('cards:list:personalQR')}<br />
                                        {t('cards:list:download')}
                                    </p>
                                    <QRCode
                                        value={qrValue}
                                        size={200}
                                        level={"H"}
                                        includeMargin={true}
                                        bgColor={"rgb(246 248 250)"}
                                    />
                                </div>
                                <div className='flex'>
                                    <Input label={t('cards:list:qrText')} className='w-full disabled overflow-hidden' value={qrValue} />
                                    <Button
                                        size="medium"
                                        variant="contained"
                                        className="bg-accent hover:bg-accent-light ml-[-10px] mt-7 min-w-[32px] h-[41.5px]"
                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                            navigator.clipboard.writeText(qrValue)
                                                .then(() => {
                                                    // console.log('Text copied to clipboard');
                                                })
                                                .catch(err => {
                                                    console.error('Failed to copy text: ', err);
                                                });
                                        }}
                                    >
                                        <ContentCopy />
                                    </Button>

                                </div>
                                <div className='flex gap-4 mt-2'>
                                    <Button variant="contained" className='bg-accent hover:bg-accent-light' onClick={downloadQr}>{t('cards:list:pdf')}</Button>
                                    <Button variant="contained" className='bg-accent hover:bg-accent-light' onClick={downloadQrAsJpg}>{t('cards:list:Jpg')}</Button>
                                    <Button variant="contained" className='bg-gray-500 hover:bg-accent-light' onClick={() => setQrModal(false)}>{t('cards:list:close')}</Button>
                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                            key="background-div"
                            onClick={() => setQrModal(false)}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 0.3 }}
                            exit={{ opacity: 0 }}
                            className='fixed left-0 top-0 w-screen h-screen bg-black' />
                    </div>
                    : null}

            </BusyArea>
        </>
    )
}

export default CardsListPage
