import { MotionConfig } from 'framer-motion';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DashboardWrapper from './DashboardWrapper';
import CardCreatePage from './Pages/Dashboard/Cards/CardCreatePage';
import CardEditPage from './Pages/Dashboard/Cards/CardEditPage';
import CardsImportPage from './Pages/Dashboard/Cards/CardsImportPage';
import CardsListPage from './Pages/Dashboard/Cards/CardsListPage';
import NotifyPage from './Pages/Dashboard/Notify/NotifyPage';
import SocialsPage from './Pages/Dashboard/Organisation/SocialsPage';
import ThemePage from './Pages/Dashboard/Organisation/ThemePage';
import UsersCreatePage from './Pages/Dashboard/Users/UsersCreatePage';
import UsersEditPage from './Pages/Dashboard/Users/UsersEditPage';
import UsersPage from './Pages/Dashboard/Users/UsersPage';
import Overview from './Pages/Dashboard/Overview/Overview';
import UserSettingsPage from './Pages/Dashboard/UserSettingsPage';
import LandingWrapper from './Pages/LandingWrapper';
import LoginPage from './Pages/Login/LoginPage';
import Page404 from './Pages/Page404';
import Modal from './Shared/Modal/Modal';
import { Provider } from 'react-redux';
import { store } from '../Redux/store';
import PrivateRoute from './Router/PrivateRoute';
import PublicRoute from './Router/PublicRoute';
import OrganisationsList from './Pages/Dashboard/AdminOrganisations/OrganisationsList';
import ReleaseNoteList from './Pages/Dashboard/AdminOrganisations/ReleaseNoteList';
import OrganisationCreatePage from './Pages/Dashboard/AdminOrganisations/OrganisationCreatePage';
import ReleaseNoteCreatePage from './Pages/Dashboard/AdminOrganisations/ReleaseNoteCreatePage';
import OrganisationEditPage from './Pages/Dashboard/AdminOrganisations/OrganisationEditPage';
import ReleaseNoteEditPage from './Pages/Dashboard/AdminOrganisations/ReleaseNoteEditPage';
import NewsPage from './Pages/Dashboard/Organisation/NewsPage';
import { JWT } from '../Utils/JWT';
import WizardPage from './Pages/Dashboard/Wizard/WizardPage';
import SuccessPage from './Pages/Dashboard/Success/SuccessPage';
import ResetPasswordPage from './Pages/Login/ResetPasswordPage';
import ForgotPasswordPage from './Pages/Login/ForgotPasswordPage';
import SponsorAddPage from './Pages/Dashboard/Sponsor/SponsorAddPage';
import SponsorListPage from './Pages/Dashboard/Sponsor/SponsorListPage';
import AddNewsPage from "./Pages/Dashboard/Organisation/AddNewsPage";
import AppBuilderPage from "./Pages/Dashboard/Organisation/AppBuilderPage";
import EditNewsPage from "./Pages/Dashboard/Organisation/EditNewsPage";

const App = () => {
    const { i18n } = useTranslation();
    const defaultLang = 'nl';

    const [notificationAccess, setNotificationAccess] = useState(() => {
        const savedNotificationAccess = sessionStorage.getItem('notificationAccess');
        return savedNotificationAccess ? savedNotificationAccess === 'true' : false;
    });

    const [sponsorAccess, setSponsorAccess] = useState(() => {
        const savedSponsorAccess = sessionStorage.getItem('sponsorAccess');
        return savedSponsorAccess ? savedSponsorAccess === 'true' : false;
    });

    const handleSettingsUpdate = (notification: boolean, sponsors: boolean) => {
        setNotificationAccess(notification);
        setSponsorAccess(sponsors);

        sessionStorage.setItem('notificationAccess', String(notification));
        sessionStorage.setItem('sponsorAccess', String(sponsors));
    };

    const isAdmin = JWT.read<Array<string>>('roles')?.includes('ROLE_SUPER_ADMIN');

    useEffect(() => {
        const validLanguages = ['nl', 'en', 'de', 'fr', 'es'];
        const currentLang = window.location.pathname.split('/')[1].toLowerCase();
        const storedLang = localStorage.getItem('preferredLanguage');

        if (validLanguages.includes(currentLang)) {
            if (currentLang !== i18n.language) {
                i18n.changeLanguage(currentLang).then(() => {
                    localStorage.setItem('preferredLanguage', currentLang);
                });
            }
        } else if (storedLang && validLanguages.includes(storedLang) && storedLang !== i18n.language && storedLang !== 'null' && storedLang !== null && storedLang !== undefined) {
            i18n.changeLanguage(storedLang).then(() => {
                window.location.replace(`/${storedLang}${window.location.pathname}`);
            });
        } else {
            if (!defaultLang) {
                i18n.changeLanguage('nl').then(() => {
                    window.location.replace(`/nl${window.location.pathname}`);
                });
            } else if (!storedLang && storedLang !== 'null' && storedLang !== null && storedLang !== undefined) {
                i18n.changeLanguage(storedLang as string).then(() => {
                    window.location.replace(`/${storedLang}${window.location.pathname}`);
                });
            } else {
                i18n.changeLanguage('nl').then(() => {
                    window.location.replace(`/nl${window.location.pathname}`);
                });
            }
        }
    }, [i18n]);

    return (
        <MotionConfig reducedMotion='user' transition={{
            ease: [0.34, 1.56, 0.64, 1],
            duration: 0.4
        }}>
            <div className='m-0 flex overflow-hidden'>
                <Modal>
                    <Provider store={store}>
                        <BrowserRouter>
                            <Routes>
                                <Route path='/:lang/dashboard' element={
                                    <PrivateRoute>
                                        <DashboardWrapper onSettingsUpdate={handleSettingsUpdate} />
                                    </PrivateRoute>
                                }>
                                    <Route path='wizard' element={<WizardPage />} />
                                    <Route path='success' element={<SuccessPage />} />
                                    <Route index element={<Overview />} />
                                    <Route path='organisation'>
                                        <Route path='appbuilder' element={<AppBuilderPage />} />
                                        <Route path='news' element={<NewsPage />} />
                                        <Route path='socials' element={<SocialsPage />} />
                                        <Route path='theme' element={<ThemePage />} />
                                        <Route path='addnews' element={<AddNewsPage />} />
                                        <Route path='newsedit/:id' element={<EditNewsPage />} />
                                    </Route>
                                    <Route path='cards'>
                                        <Route index element={<CardsListPage />} />
                                        <Route path='list' element={<CardsListPage />} />
                                        <Route path='import' element={<CardsImportPage />} />
                                        <Route path='create' element={<CardCreatePage />} />
                                        <Route path='edit/:id' element={<CardEditPage />} />
                                    </Route>
                                    <Route path='users'>
                                        <Route index element={<UsersPage />} />
                                        <Route path='list' element={<UsersPage />} />
                                        <Route path='create' element={<UsersCreatePage />} />
                                        <Route path='edit/:id' element={<UsersEditPage />} />
                                    </Route>

                                    {sponsorAccess && (
                                        <Route path='sponsor'>
                                            <Route path='list' element={<SponsorListPage />} />
                                            <Route path='add' element={<SponsorAddPage />} />
                                        </Route>
                                    )}

                                    {isAdmin && (
                                        <Route path='admin'>
                                            <Route index element={<OrganisationsList />} />
                                            <Route path='organisation'>
                                                <Route index element={<OrganisationsList />} />
                                                <Route path='list' element={<OrganisationsList />} />
                                                <Route path='create' element={<OrganisationCreatePage />} />
                                                <Route path='edit/:id' element={<OrganisationEditPage />} />
                                            </Route>
                                            <Route path='releaseNote'>
                                                <Route index element={<ReleaseNoteList />} />
                                                <Route path='list' element={<ReleaseNoteList />} />
                                                <Route path='create' element={<ReleaseNoteCreatePage />} />
                                                <Route path='edit/:id' element={<ReleaseNoteEditPage />} />
                                            </Route>
                                        </Route>
                                    )}

                                    {notificationAccess && (
                                        <Route path='notify' element={<NotifyPage />} />
                                    )}

                                    {/* <Route path='settings' element={<UserSettingsPage />} /> */}
                                    <Route path='*' element={<Page404 />} />
                                </Route>
                                <Route path='/:lang/' element={
                                    <PublicRoute>
                                        <LandingWrapper />
                                    </PublicRoute>
                                }>
                                    <Route index element={<LoginPage />} />
                                    <Route path='login' element={<LoginPage />} />
                                    <Route path='forgotpassword' element={<ForgotPasswordPage />} />
                                    <Route path='resetpassword' element={<ResetPasswordPage />} />
                                </Route>
                                <Route path='/' element={<Navigate to={`/${localStorage.getItem('preferredLanguage') || defaultLang}/login`} replace />} />
                                <Route path='*' element={<Page404 />} />
                            </Routes>
                        </BrowserRouter>
                    </Provider>
                </Modal>
            </div>
        </MotionConfig>
    );
}

export default App;
